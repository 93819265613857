import React, { useState } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TableContainer } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

// DatePicker components
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";

// firebase functions
import { updateDoc, doc, Timestamp } from "firebase/firestore";
import { db } from "firebaseDb";

require("dayjs/locale/en-au");

// Setting Locale
dayjs.locale("en-au");

function WeedSprayBlockList({ title, sprayInfo, shadow, setSprayInfo }) {
  const [loading, setLoading] = useState(false);

  const filteredOrchards = sprayInfo.orchards
    .filter((o) => o.checked) // Filter orchards based on checked property
    .map((orchard) => ({
      ...orchard,
      blocks: orchard.blocks
        .filter((b) => b.checked) // Filter blocks based on checked property
        .map((block) => ({
          ...block,
          varieties: block.varieties.filter((v) => v.blockVarietyActive), // Filter varieties based on blockVarietyActive property
        })),
    }));
  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <TableContainer>
        <Table aria-label="simple table">
          <TableBody>
            {filteredOrchards.map((row, index) => (
              <Accordion key={row.siteCode} defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <MDTypography style={{ flex: 1 }}>
                      {`${row.siteCode} - ${row.siteName}`}
                    </MDTypography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer>
                    <Table aria-label="blocks table">
                      <TableBody>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }} align="left">
                            Block
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }} align="left">
                            Varieties
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }} align="left">
                            Hectares
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }} align="left">
                            Liters
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }} align="left">
                            Actual Liters
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }} align="left">
                            Date Sprayed
                          </TableCell>
                        </TableRow>
                        {row.blocks.map((block, blockIndex) => {
                          const totalHectares = block.varieties.reduce(
                            (sum, item) => sum + parseFloat(item.hectares),
                            0
                          );
                          const totalVolume = (sprayInfo.waterRate * totalHectares).toFixed(0);
                          const calculatedValue = (
                            (sprayInfo.waterRate * totalHectares) /
                            sprayInfo.tankCapacity
                          ).toFixed(1);

                          return (
                            <TableRow
                              key={block.blockCode}
                              align="right"
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                                backgroundColor: blockIndex % 2 === 0 ? "#b2d6bb" : "#e3fad4",
                              }}
                            >
                              <TableCell align="left">{block.blockCode}</TableCell>
                              <TableCell align="left">
                                {block.varieties.map((c) => c.varietyName).join("\r\n")}
                              </TableCell>
                              <TableCell align="left">{totalHectares.toFixed(2)}</TableCell>
                              <TableCell align="left">
                                {totalVolume}L ({calculatedValue} Tanks)
                              </TableCell>
                              <TableCell align="left">
                                <TextField
                                  InputProps={{
                                    style: {
                                      backgroundColor: row.disabled ? "lightgrey" : "lightgreen",
                                    },
                                  }}
                                  value={totalVolume}
                                  disabled={row.disabled}
                                />
                              </TableCell>
                              <TableCell align="left">
                                {!loading ? (
                                  <MDBox key={block} display="flex" py={1} pr={2}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                      <DatePicker
                                        label="Spray Date"
                                        value={block.sprayDate ? block.sprayDate : ""}
                                        onChange={(newDate) => {
                                          if (newDate) {
                                            const firebaseTimestamp = Timestamp.fromDate(
                                              new Date(newDate)
                                            );

                                            // Create the new invoices object
                                            const newInvoice = {
                                              [row.siteCode]: {
                                                key: "6Nk2PZk3BQhVC0CXr8Vs",
                                                orchard: row.siteName,
                                                orchardCode: row.siteCode,
                                                orchardGUID: "",
                                                date: firebaseTimestamp,
                                                chemicalName: sprayInfo.chemicals[0].chemicalName,
                                                billableCost: 808.81,
                                                block: block.blockCode,
                                              },
                                            };
                                            // Update the sprayInfo state
                                            setSprayInfo((prevState) => {
                                              // Merge the new invoice with the existing invoices
                                              const updatedInvoices = {
                                                ...prevState.invoices,
                                                ...newInvoice,
                                              };

                                              // Return the new state with updated invoices
                                              return {
                                                ...prevState,
                                                invoices: updatedInvoices,
                                              };
                                            });

                                            const sprayRef = doc(db, "Weedsprays", sprayInfo.key);
                                            const fieldPath = `orchards.${index}.blocks.${blockIndex}.sprayDate`;
                                            if (sprayInfo === 1) {
                                              updateDoc(sprayRef, {
                                                [fieldPath]: firebaseTimestamp,
                                              }) // Use the Firebase Timestamp
                                                .then(() => {
                                                  setLoading(false);
                                                  console.log("Document successfully updated!");
                                                })
                                                .catch((error) => {
                                                  setLoading(false);
                                                  console.error("Error updating document: ", error);
                                                  alert("Error");
                                                });
                                            }

                                            // {"key":"6Nk2PZk3BQhVC0CXr8Vs","orchard":"Cherry Pies","orchardCode":"S4208","orchardGUID":"","date":"06-06-2024","chemicalName":"Tri-Base Blue","billableCost":808.81}
                                          }
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                      />
                                    </LocalizationProvider>
                                  </MDBox>
                                ) : (
                                  <MDBox
                                    key={block}
                                    display="flex"
                                    justifyContent="center"
                                    py={1}
                                    pr={2}
                                  >
                                    <CircularProgress />
                                  </MDBox>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

WeedSprayBlockList.defaultProps = {
  shadow: true,
};

WeedSprayBlockList.propTypes = {
  title: PropTypes.string.isRequired,
  shadow: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  sprayInfo: PropTypes.object.isRequired,
};

export default WeedSprayBlockList;
