// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import OrchardTimeline from "examples/Timeline/OrchardTimeline";
import BlockTimeline from "examples/Timeline/BlockTimeline";

function PruningOverview(props) {
  const {
    orchardInfo,
    setOrchardInfo,
    currentOrchard,
    setCurrentOrchard,
    blockInfo,
    currentBlock,
    setCurrentBlock,
  } = props;

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Pruning overview
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <OrchardTimeline
          color="success"
          icon="map"
          title="Orchard"
          orchardInfo={orchardInfo}
          setOrchardInfo={setOrchardInfo}
          currentOrchard={currentOrchard}
          setCurrentOrchard={setCurrentOrchard}
        />
        {currentOrchard && (
          <BlockTimeline
            color="primary"
            icon="square"
            title="Block"
            currentBlock={currentBlock}
            setCurrentBlock={setCurrentBlock}
            blockInfo={blockInfo}
          />
        )}
      </MDBox>
    </Card>
  );
}

export default PruningOverview;
