// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";
// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

function PackingOverview({ averages }) {
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Packout overview
        </MDTypography>
        <MDBox mt={0} mb={2}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            <MDTypography display="inline" variant="body2" verticalAlign="middle">
              <Icon sx={{ color: ({ palette: { success } }) => success.main }}>percent</Icon>
            </MDTypography>
            &nbsp;
            <MDTypography variant="button" color="text" fontWeight="medium">
              3
            </MDTypography>{" "}
            Day Average
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        <TimelineItem
          color="success"
          icon="forest"
          dateTime="Average Percentage of Export"
          title={`${averages.export}%`}
        />
        <TimelineItem
          color="warning"
          icon="forest"
          title={`${averages.dom}%`}
          dateTime="Average Percentage of Domestic"
        />
        <TimelineItem
          color="error"
          icon="forest"
          title={`${averages.cull}%`}
          dateTime="Average Percentage of Cull"
        />

        <TimelineItem
          color="info"
          icon="info"
          title="Varieties"
          dateTime={averages.varieties.join(", ")}
          lastItem
        />
      </MDBox>
    </Card>
  );
}

// Typechecking props for the PackingOverview
PackingOverview.propTypes = {
  averages: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string])
  ).isRequired,
};

export default PackingOverview;
