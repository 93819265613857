import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
// import { BrowserRouter } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import App from "App";
// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";

ReactDOM.render(
  <StrictMode>
    <HashRouter>
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </HashRouter>
  </StrictMode>,
  document.getElementById("root")
);
