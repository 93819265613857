import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Menu from "@mui/material/Menu";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import BlockListEdit from "examples/Lists/WeedsprayBlockListEdit";

// firebase functions
import { db } from "firebaseDb";
import { getDocs, query, collection, orderBy, where } from "firebase/firestore";

function Orchard({ spray, setSpray }) {
  const [orchards, setOrchards] = useState([]);
  const [dropdown, setDropdown] = useState(null);
  const [blockbg, setBlockBG] = useState("lightgrey");
  const [accordiantitle, setAccordianTitle] = useState("Orchard Info - No Blocks");
  // Styles
  const iconStyles = {
    ml: 1,
    fontWeight: "bold",
    transition: "transform 200ms ease-in-out",
  };
  const dropdownIconStyles = {
    transform: dropdown ? "rotate(180deg)" : "rotate(0)",
    ...iconStyles,
  };

  const openDropdown = ({ currentTarget }) => setDropdown(currentTarget);
  const closeDropdown = () => setDropdown(null);

  const handleSelectOrchard = async (areaObj) => {
    const areaOrchards = areaObj.orchards;
    setSpray((prevState) => ({
      ...prevState,
      area: areaObj.area,
      orchards: [], // Initialize orchards array
    }));

    try {
      const promises = areaOrchards.map(async (orchard) => {
        const q = query(collection(db, "Orchards"), where("siteCode", "==", orchard.siteCode));
        const querySnapshot = await getDocs(q);
        const blocks = querySnapshot.docs.map((doc) => doc.data());
        return { siteCode: orchard.siteCode, siteName: orchard.orchardName, blocks };
      });

      const results = await Promise.all(promises);

      // Group results by siteCode and blockCode
      const groupedBlocks = results.reduce((acc, { siteCode, siteName, blocks }) => {
        if (!acc[siteCode]) acc[siteCode] = { siteName, blocks: {} };
        blocks.forEach((block) => {
          const { blockCode } = block;
          if (!acc[siteCode].blocks[blockCode]) {
            acc[siteCode].blocks[blockCode] = {
              blockCode,
              checked: true,
              varieties: [],
            };
          }
          acc[siteCode].blocks[blockCode].varieties.push(block);
        });
        return acc;
      }, {});

      // Convert groupedBlocks object to array format
      const orchardsArray = Object.keys(groupedBlocks).map((siteCode) => ({
        siteCode,
        checked: true,
        siteName: groupedBlocks[siteCode].siteName,
        blocks: Object.values(groupedBlocks[siteCode].blocks),
      }));

      setSpray((prevState) => ({
        ...prevState,
        orchards: orchardsArray,
      }));

      closeDropdown();
    } catch (error) {
      console.error("Error fetching orchard data:", error);
      closeDropdown();
    }
  };

  useEffect(() => {
    // Fetch Data
    const sprayRef = collection(db, "OrchardCodes");
    const qs = query(sprayRef, orderBy("area", "asc"), where("archive", "==", false));

    getDocs(qs)
      .then((querySnapshot) => {
        const temporchards = [];
        querySnapshot.forEach((docu) => {
          temporchards.push({
            key: docu.id,
            siteCode: docu.data().siteCode,
            orchardName: docu.data().orchardName,
            orchardGUID: docu.data().orchardGUID,
            area: docu.data().area,
          });
        });

        // Group orchards by area
        const groupedOrchards = temporchards.reduce((acc, orchard) => {
          const { area } = orchard;
          if (!acc[area]) {
            acc[area] = [];
          }
          acc[area].push({
            siteCode: orchard.siteCode,
            orchardGUID: orchard.orchardGUID,
            orchardName: orchard.orchardName,
          });
          return acc;
        }, {});

        // Transform grouped orchards into desired format
        const result = Object.keys(groupedOrchards).map((area) => ({
          area,
          orchards: groupedOrchards[area],
        }));

        setOrchards(result);
      })
      .catch((error) => {
        console.error("Error fetching orchards: ", error);
      });
  }, []);

  function checkAllChecked(obj) {
    if (Array.isArray(obj)) {
      return obj.every((item) => checkAllChecked(item));
    }

    if (typeof obj === "object") {
      if ("checked" in obj) {
        if (!obj.checked) {
          return false;
        }
      }

      return Object.values(obj).every((value) => checkAllChecked(value));
    }

    return true;
  }

  useEffect(() => {
    // Calculate Hectares
    let sum = 0;
    /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
    for (let i = 0; i < spray.orchards.length; i++) {
      for (let j = 0; j < spray.orchards[i].blocks.length; j++) {
        for (let k = 0; k < spray.orchards[i].blocks[j].varieties.length; k++) {
          if (spray.orchards[i].blocks[j].checked) {
            sum += Number(spray.orchards[i].blocks[j].varieties[k].hectares);
          }
        }
      }
    }
    const hec = Math.round(sum * 100) / 100;

    if (spray.orchards.length > 0) {
      if (checkAllChecked(spray.orchards)) {
        setBlockBG("lightgreen");
        setAccordianTitle(`Orchard Info - All Blocks Selected - ${hec} Hectares`);
      } else {
        setBlockBG("lightyellow");
        setAccordianTitle(`Orchard Info - Only Some Blocks Selected - ${hec} Hectares`);
      }
    }
  }, [spray]);

  return (
    <MDBox mb={5}>
      <Grid item xs={12} md={12} lg={4}>
        <MDButton
          sx={{ minWidth: "300px", marginBottom: "15px", marginTop: "15px" }}
          variant="outlined"
          color="secondary"
          onClick={openDropdown}
        >
          <MDTypography>
            {`${spray.area ? spray.area : "Select an Area"}`}{" "}
            <Icon sx={dropdownIconStyles}>expand_more</Icon>
          </MDTypography>
        </MDButton>

        <Menu anchorEl={dropdown} open={Boolean(dropdown)} onClose={closeDropdown}>
          {orchards.length > 0 ? (
            orchards.map((site) => (
              <MenuItem
                key={site.siteCode}
                onClick={() => {
                  handleSelectOrchard(site);
                }}
              >
                {`${site.area}`}
              </MenuItem>
            ))
          ) : (
            <CircularProgress color="success" />
          )}
        </Menu>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Accordion sx={{ backgroundColor: blockbg }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <MDTypography sx={{ paddingRight: "10px" }}>{accordiantitle}</MDTypography>
          </AccordionSummary>
          <AccordionDetails>
            <BlockListEdit sprayInfo={spray} title="" setSprayInfo={setSpray} />
          </AccordionDetails>
        </Accordion>
      </Grid>
    </MDBox>
  );
}

// Typechecking props
Orchard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  spray: PropTypes.object.isRequired,
  setSpray: PropTypes.func.isRequired,
};

export default Orchard;
