/* eslint-disable no-plusplus */
// @mui material components
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import OrchardListEdit from "examples/Lists/OrchardListEdit";
import { useLocation, useNavigate } from "react-router-dom";

// React
import { useState } from "react";

// firebase functions
import { db } from "firebaseDb";
import { doc, writeBatch } from "firebase/firestore";

function AddOrchard() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { state } = useLocation();
  const [orchardInfo, setOrchardInfo] = useState({
    key: JSON.stringify(10000000000000000 * Math.random(8)),
    siteName: "",
    siteCode: "",
    blocks: [
      {
        blockCode: "",
        blockName: "",
        blockActive: "Yes",
        varieties: [
          {
            key: JSON.stringify(10000000000000000 * Math.random(8)),
            varietyCode: "",
            manager: "",
            blockName: "",
            siteName: "",
            trees: "",
            blockVarietyActive: "Yes",
            hectares: "",
            blockActive: "Yes",
            address: "",
            varietyName: "",
            siteActive: "Yes",
            grower: "45 South",
            yearPlanted: "",
          },
        ],
      },
    ],
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const batch = writeBatch(db);
  const saveOrchard = () => {
    // setLoading(true);
    if (orchardInfo) {
      for (let i = 0; i < orchardInfo.blocks.length; i++) {
        for (let j = 0; j < orchardInfo.blocks[i].varieties.length; j++) {
          const orcRef = doc(db, "Orchards", orchardInfo.blocks[i].varieties[j].key);
          // Queue the set operation in the batch
          if (orchardInfo.blocks[i].varieties[j].key) {
            batch.set(orcRef, orchardInfo.blocks[i].varieties[j], { merge: true });
            batch.set(
              orcRef,
              {
                orchardGUID: orchardInfo.orchardGUID,
                cropSureID: orchardInfo.cropSureID,
                cropSureBlockID: orchardInfo.blocks[i].cropSureBlockID,
                siteName: orchardInfo.siteName,
                siteCode: orchardInfo.siteCode,
                blockName: orchardInfo.blocks[i].blockName,
                blockCode: orchardInfo.blocks[i].blockCode,
              },
              { merge: true }
            );
          } else {
            console.log(orchardInfo.blocks[i].varieties[j]);
          }
        }
      }

      // Commit the batch to Firestore
      batch
        .commit()
        .then(() => {
          console.log("Batch write completed successfully.");
          setLoading(false);
          navigate("/setup");
        })
        .catch((error) => {
          alert("Error performing batch write:", error);
          setLoading(false);
        });
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox
        component="li"
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        bgColor={darkMode ? "transparent" : "grey-100"}
        borderRadius="lg"
        p={3}
        mb={1}
        mt={10}
      >
        {!loading && (
          <MDBox width="100%" display="flex" flexDirection="column">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              mb={2}
            >
              <MDTypography
                variant="caption"
                color="text"
                mr={2}
                sx={{
                  fontSize: "1rem",
                  verticalAlign: "center",
                  left: "30px",
                  position: "relative",
                }}
              >
                Orchard:
              </MDTypography>
              <TextField
                sx={{ left: "38px", position: "relative" }}
                value={orchardInfo.siteName}
                onChange={(e) => {
                  setOrchardInfo((prevState) => ({
                    ...prevState,
                    siteName: e.target.value,
                  }));
                }}
              />
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              mb={2}
            >
              <MDTypography
                variant="caption"
                color="text"
                mr={2}
                sx={{
                  fontSize: "1rem",
                  verticalAlign: "center",
                  left: "30px",
                  position: "relative",
                }}
              >
                OrchardGUID:
              </MDTypography>
              <TextField
                sx={{ left: "38px", position: "relative" }}
                value={orchardInfo.orchardGUID}
                onChange={(e) => {
                  setOrchardInfo((prevState) => ({
                    ...prevState,
                    orchardGUID: e.target.value,
                  }));
                }}
              />
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              mb={2}
            >
              <MDTypography
                variant="caption"
                color="text"
                mr={2}
                sx={{
                  fontSize: "1rem",
                  verticalAlign: "center",
                  left: "30px",
                  position: "relative",
                }}
              >
                CropSure ID:
              </MDTypography>
              <TextField
                sx={{ left: "38px", position: "relative" }}
                value={orchardInfo.cropSureID}
                onChange={(e) => {
                  setOrchardInfo((prevState) => ({
                    ...prevState,
                    cropSureID: e.target.value,
                  }));
                }}
              />
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              mb={2}
            >
              <MDTypography
                variant="caption"
                color="text"
                sx={{
                  fontSize: "1rem",
                  verticalAlign: "center",
                  left: "30px",
                  position: "relative",
                }}
              >
                Site Code:
              </MDTypography>
              <TextField
                sx={{
                  fontSize: "1rem",
                  verticalAlign: "center",
                  left: "40px",
                  position: "relative",
                }}
                type="text"
                value={orchardInfo.siteCode}
                onChange={(e) => {
                  setOrchardInfo((prevState) => ({
                    ...prevState,
                    siteCode: e.target.value,
                  }));
                }}
              />
            </MDBox>
            <MDBox>
              {orchardInfo && (
                <OrchardListEdit
                  title="Block List"
                  orchardInfo={orchardInfo}
                  setOrchardInfo={setOrchardInfo}
                  varieties={state.varieties}
                />
              )}
            </MDBox>
          </MDBox>
        )}
        {loading && <CircularProgress />}
      </MDBox>
      <MDBox mb={1} lineHeight={0} textAlign="center">
        <MDButton
          variant="contained"
          color="success"
          disabled={loading}
          onClick={() => {
            saveOrchard();
          }}
        >
          <Icon>save</Icon>&nbsp;save
        </MDButton>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddOrchard;
