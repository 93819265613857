/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */

// @mui material components
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/pruning/data/reportsBarChartData";
import reportsLineChartData from "layouts/pruning/data/reportsLineChartData";

// Dashboard components
import Rows from "layouts/pruning/components/Rows";
import PruningOverview from "layouts/pruning/components/PruningOverview";

// Dashboard components

// // React
import { useEffect, useState } from "react";
import { onSnapshot, collection, doc, setDoc } from "firebase/firestore";
import { db } from "firebaseDb";
// import {
//   getDocs,
//   collectionGroup,
//   collection,
//   where,
//   query,
//   setDoc,
//   doc,
// } from "firebase/firestore";

function Pruning() {
  const { sales, tasks } = reportsLineChartData;

  const [orchards, setOrchards] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [orchardInfo, setOrchardInfo] = useState([]);
  const [currentOrchard, setCurrentOrchard] = useState("");
  const [currentBlock, setCurrentBlock] = useState("");
  const [blockInfo, setBlockInfo] = useState([]);
  const [rowInfo, setRowInfo] = useState([]);
  const [loadingdata, setLoading] = useState(false);

  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const formattedDate = `${day} / ${month} / ${year}`;

  useEffect(() => {
    const employeeCollectionRef = collection(db, "Employees");
    const unsubscribe = onSnapshot(employeeCollectionRef, (snapshot) => {
      const tempEmployees = [];
      snapshot.forEach((change) => {
        const employeeData = change.data();
        const employeeID = change.id;
        const tempEmployee = { ...employeeData, employeeID };

        // Push the row object into the tempRows array
        tempEmployees.push(tempEmployee);
      });

      setEmployees(tempEmployees);
    });

    return () => {
      // Unsubscribe from the onSnapshot listener when component unmounts
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const orchardsCollectionRef = collection(db, "PruningBlocks");
    const unsubscribe = onSnapshot(orchardsCollectionRef, (snapshot) => {
      const tempOrchards = [];
      snapshot.docChanges().forEach((change) => {
        const orchardData = change.doc.data();
        const orchardId = change.doc.id;
        tempOrchards.push({ ...orchardData, orchardId });
      });
      setOrchardInfo(tempOrchards);
    });

    return () => {
      // Unsubscribe from the onSnapshot listener when component unmounts
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (currentOrchard.orchardId) {
      const blockCollectionRef = collection(
        db,
        "PruningBlocks",
        currentOrchard.orchardId,
        "Blocks"
      );
      const unsubscribe = onSnapshot(blockCollectionRef, (snapshot) => {
        const tempBlocks = [];
        snapshot.docChanges().forEach((change) => {
          const blockData = change.doc.data();
          const blockID = change.doc.id;
          tempBlocks.push({ ...blockData, blockID });
        });
        setBlockInfo(tempBlocks);
      });

      return () => {
        // Unsubscribe from the onSnapshot listener when component unmounts
        unsubscribe();
      };
    }

    return () => {}; // Return an empty cleanup function as a default value
  }, [currentOrchard]);

  useEffect(() => {
    if (currentBlock.blockID) {
      const rowCollectionRef = collection(
        db,
        "PruningBlocks",
        currentOrchard.orchardId,
        "Blocks",
        currentBlock.blockID,
        "Rows"
      );
      const unsubscribe = onSnapshot(rowCollectionRef, (snapshot) => {
        const tempRows = [];
        snapshot.forEach((change) => {
          const rowData = change.data();
          const rowID = change.id;
          const tempRow = { ...rowData, rowID };

          // Push the row object into the tempRows array
          tempRows.push(tempRow);
        });

        setRowInfo(tempRows);
      });

      return () => {
        // Unsubscribe from the onSnapshot listener when component unmounts
        unsubscribe();
      };
    }

    return () => {}; // Return an empty cleanup function as a default value
  }, [currentBlock]);

  const addTreesAndRows = async (orchardId, blockId, numRows, numTreesPerRow, variety, style) => {
    const blockRef = doc(db, "PruningBlocks", orchardId, "Blocks", blockId);
    for (let row = 1; row <= numRows; row++) {
      const rowRef = doc(blockRef, "Rows", `Row${row.toString().padStart(3, "0")}`);
      const trees = [];
      for (let tree = 1; tree <= numTreesPerRow[row - 1]; tree++) {
        const treeData = {
          treeNumber: tree.toString().padStart(3, "0"),
          prunedBy: "",
          prunedDate: null,
        };
        trees.push(treeData);
        await setDoc(
          rowRef,
          {
            variety: variety[row - 1],
            style,
            numTreesPerRow: numTreesPerRow[row - 1],
            orchardId,
            blockId,
            trees,
          },
          { merge: true }
        );
      }
    }

    console.log("Trees and rows added successfully!");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="trees pruned per person per hour"
                  description="Piecework Pruning"
                  date={formattedDate}
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <PruningOverview
                orchardInfo={orchardInfo}
                setOrchardInfo={setOrchardInfo}
                currentOrchard={currentOrchard}
                setCurrentOrchard={setCurrentOrchard}
                currentBlock={currentBlock}
                setCurrentBlock={setCurrentBlock}
                blockInfo={blockInfo}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
              <Rows rowInfo={rowInfo} employees={employees} />
            </Grid>
            <Button
              onClick={() => {
                const rowCounts = [
                  6, 6, 11, 12, 13, 16, 17, 21, 22, 24, 19, 17, 18, 18, 17, 19, 19, 19, 18, 21, 21,
                  21, 21, 21, 38, 40, 62, 61, 67, 70, 72, 73, 76, 78, 80, 80, 84, 85, 85, 85, 85,
                  83, 83, 82, 57,
                ];
                const varietyRows = [
                  "Lapin",
                  "Lapin",
                  "Lapin",
                  "Lapin",
                  "Lapin",
                  "Lapin",
                  "Lapin",
                  "Lapin",
                  "Lapin",
                  "Lapin",
                  "Lapin",
                  "Lapin",
                  "Lapin",
                  "Lapin",
                  "Lapin",
                  "Lapin",
                  "Lapin",
                  "Lapin",
                  "Lapin",
                  "Lapin",
                  "Lapin",
                  "Lapin",
                  "Lapin",
                  "Staccato",
                  "Staccato",
                  "Sweetheart",
                  "Sweetheart",
                  "Sweetheart",
                  "Staccato",
                  "Staccato",
                  "Staccato",
                  "Staccato",
                  "Sweetheart",
                  "Sweetheart",
                  "Sweetheart",
                  "Sweetheart",
                  "Staccato",
                  "Staccato",
                  "Staccato",
                  "Staccato",
                  "Sweetheart",
                  "Sweetheart",
                  "Sweetheart",
                  "Sweetheart",
                  "Sweetheart",
                ];
                addTreesAndRows(
                  "S4220",
                  "Block 1",
                  45,
                  rowCounts,
                  varietyRows,
                  "Central Leader"
                ).catch((error) => {
                  console.error("Error adding trees and rows:", error);
                });
              }}
            >
              Upload
            </Button>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Pruning;
