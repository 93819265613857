/**
=========================================================
* Material Dashboard 2 React - v2.1.0


/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Sprays from "layouts/sprays";
import WeedSprays from "layouts/weedsprays";
import CreateSpray from "layouts/sprays/createspray";
import CreateWeedSpray from "layouts/weedsprays/createweedspray";
import CloneWeedSpray from "layouts/weedsprays/cloneweedspray";
import EditWeedSpray from "layouts/weedsprays/editweedspray";
import WeedSprayDetail from "layouts/weedsprays/weedspraydetail/";
import WeedSprayPDF from "layouts/weedsprays/weedsprayPDF";
import CloneSpray from "layouts/sprays/clonespray";
import Billing from "layouts/billing";
import Profile from "layouts/profile";
import Pruning from "layouts/pruning";
import SprayDetail from "layouts/spraydetail/";
import SprayPDF from "layouts/sprayPDF/";
import EditSpray from "layouts/editspray/";
import CreateInvoice from "layouts/createinvoice";
import InvoiceDetails from "layouts/invoicedetails";
import InvoiceGrid from "layouts/invoicegrid";
import Setup from "layouts/setup";
import ChemicalEdit from "layouts/setup/components/ChemicalEdit";
import ChemicalAdd from "layouts/setup/components/ChemicalAdd";
import EmployeeEdit from "layouts/setup/components/EmployeeEdit";
import EmployeeAdd from "layouts/setup/components/EmployeeAdd";
import SprayerEdit from "layouts/setup/components/SprayerEdit";
import SprayerAdd from "layouts/setup/components/SprayerAdd";
import OrchardEdit from "layouts/setup/components/OrchardEdit";
import OrchardAdd from "layouts/setup/components/OrchardAdd";
// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    protected: true,
    admin: false,
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Spray Records",
    key: "sprays",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">agriculture</Icon>,
    route: "/sprays",
    component: <Sprays />,
  },
  {
    type: "hidden",
    name: "Create Spray",
    key: "createspray",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">add</Icon>,
    route: "/sprays/createspray",
    component: <CreateSpray />,
  },
  {
    type: "hidden",
    name: "Create Weed Spray",
    key: "createweedspray",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">add</Icon>,
    route: "/weedsprays/createweedspray",
    component: <CreateWeedSpray />,
  },
  {
    type: "hidden",
    name: "Spray Info",
    key: "spraydetail",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">add</Icon>,
    route: "/sprays/:sprayId",
    component: <SprayDetail />,
  },
  {
    type: "hidden",
    name: "Weed Spray Info",
    key: "weedspraydetail",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">add</Icon>,
    route: "/weedsprays/:sprayId",
    component: <WeedSprayDetail />,
  },
  {
    type: "hidden",
    name: "Spray PDF",
    key: "spraypdf",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">add</Icon>,
    route: "/sprays/:sprayId/pdf",
    component: <SprayPDF />,
  },
  {
    type: "hidden",
    name: "Spray PDF",
    key: "spraypdf",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">add</Icon>,
    route: "/weedsprays/:sprayId/pdf",
    component: <WeedSprayPDF />,
  },
  {
    type: "hidden",
    name: "Clone Spray",
    key: "clonespray",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">add</Icon>,
    route: "/sprays/clonespray",
    component: <CloneSpray />,
  },
  {
    type: "hidden",
    name: "Clone Weed Spray",
    key: "cloneweedspray",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">add</Icon>,
    route: "/weedsprays/cloneweedspray",
    component: <CloneWeedSpray />,
  },
  {
    type: "collapse",
    name: "Weed Sprays",
    key: "weedspray",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">grass</Icon>,
    route: "/weedsprays",
    component: <WeedSprays />,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },

  {
    type: "collapse",
    name: "Pruning",
    key: "pruning",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">forest</Icon>,
    route: "/pruning",
    component: <Pruning />,
  },
  {
    type: "hidden",
    name: "Profile",
    key: "profile",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "hidden",
    name: "Sign In",
    key: "sign-in",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">login</Icon>,
    route: "*",
    component: <Dashboard />,
  },
  {
    type: "hidden",
    name: "Edit Spray",
    key: "editspray",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">login</Icon>,
    route: "/editspray/:sprayId",
    component: <EditSpray />,
  },
  {
    type: "hidden",
    name: "Edit Weed Spray",
    key: "editweedspray",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">login</Icon>,
    route: "/editweedspray/:sprayId",
    component: <EditWeedSpray />,
  },
  {
    type: "hidden",
    name: "Create Invoice",
    key: "createinvoice",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">login</Icon>,
    route: "/billing/createinvoice",
    component: <CreateInvoice />,
  },
  {
    type: "hidden",
    name: "Invoice Details",
    key: "invoicedetails",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">login</Icon>,
    route: "/billing/:invoiceID",
    component: <InvoiceDetails />,
  },
  {
    type: "hidden",
    name: "Invoice Grid",
    key: "invoice grid",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">login</Icon>,
    route: "/billing/:invoiceID/grid",
    component: <InvoiceGrid />,
  },
  {
    type: "collapse",
    name: "Setup",
    key: "setup",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/setup/",
    component: <Setup />,
  },
  {
    type: "hidden",
    name: "EditChem",
    key: "editchem",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/setup/chem/:chemID",
    component: <ChemicalEdit />,
  },
  {
    type: "hidden",
    name: "AddChem",
    key: "addchem",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/setup/addchem/",
    component: <ChemicalAdd />,
  },
  {
    type: "hidden",
    name: "EditEmployee",
    key: "editemployee",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/setup/employee/:empID",
    component: <EmployeeEdit />,
  },
  {
    type: "hidden",
    name: "AddEmployee",
    key: "addemployee",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/setup/addemployee/",
    component: <EmployeeAdd />,
  },
  {
    type: "hidden",
    name: "EditSprayer",
    key: "editsprayer",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/setup/sprayer/:sprayerID",
    component: <SprayerEdit />,
  },
  {
    type: "hidden",
    name: "AddSprayer",
    key: "addsprayer",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/setup/addsprayer/",
    component: <SprayerAdd />,
  },
  {
    type: "hidden",
    name: "EditOrchard",
    key: "editorchard",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/setup/orchard/:orchardID",
    component: <OrchardEdit />,
  },
  {
    type: "hidden",
    name: "AddOrchard",
    key: "addorchard",
    protected: true,
    admin: true,
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/setup/addorchard/",
    component: <OrchardAdd />,
  },
];

export default routes;
