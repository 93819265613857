/* eslint-disable no-plusplus */
// react-routers components
// import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// Styles
import "./styles.css";
// React
import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import ChemicalTable from "examples/Tables/ChemicalTable";

// firebase functions
// import {
// getDoc,
// doc,
// setDoc,
// Timestamp,
// setDoc,
// doc,
// } from "firebase/firestore";
// import { db } from "../../../firebaseDb";

const totalChemical = (
  amount,
  measure,
  hectares,
  unit,
  waterRate,
  tankCapacity,
  actualChemical
) => {
  const tanksperHectare = waterRate / tankCapacity;

  if (actualChemical) {
    return {
      text: `${Math.round(100 * actualChemical) / 100} ${unit}`,
      number: Math.round(100 * actualChemical) / 100,
    };
  }
  if (measure === "Tank") {
    return {
      text: `${Math.round(100 * amount * tanksperHectare * hectares) / 100} ${unit}`,
      number: Math.round(100 * amount * tanksperHectare * hectares) / 100,
    };
  }
  if (measure === "Hectare") {
    return {
      text: `${Math.round(100 * Number(amount) * hectares) / 100} ${unit}`,
      number: Math.round(100 * Number(amount) * hectares) / 100,
    };
  }
  if (measure === "1000 L") {
    return {
      text: `${Math.round((100 * Number(amount) * waterRate * hectares) / 1000) / 100} ${unit}`,
      number: Math.round((100 * Number(amount) * waterRate * hectares) / 1000) / 100,
    };
  }

  return null;
};

const totalSprayCost = (spray, chemicals) => {
  let sum = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < spray.chemicals.length; i++) {
    const chem = spray.chemicals[i];
    sum +=
      totalChemical(
        chem.sprayRate,
        chem.measure,
        spray.hectares,
        chem.unit,
        spray.waterRate,
        spray.tankCapacity,
        chem.actualChemical
      ).number * chemicals.chemicals.filter((c) => c.key === chem.key)[0].cost;
  }
  return sum;
};

const totalOrchardCost = (invoiceData, index, chemicals) => {
  let sum = 0;
  const orchardData = invoiceData[index];
  // eslint-disable-next-line no-plusplus
  for (let j = 0; j < orchardData.sprays.length; j++) {
    const spray = orchardData.sprays[j];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < spray.chemicals.length; i++) {
      const chem = spray.chemicals[i];
      if (chem.unBilled) {
        sum += 0;
      } else {
        sum +=
          totalChemical(
            chem.sprayRate,
            chem.measure,
            spray.hectares,
            chem.unit,
            spray.waterRate,
            spray.tankCapacity,
            chem.actualChemical
          ).number * chemicals.chemicals.filter((c) => c.key === chem.key)[0].cost;
      }
    }
  }

  return sum;
};

const TimestamptoDate = (timestamp) => {
  const { seconds } = timestamp;
  const { nanoseconds } = timestamp;

  const milliseconds = seconds * 1000 + nanoseconds / 1e6;
  const date = new Date(milliseconds).toDateString();
  return date;
};

function InvoiceSprayListGrid({ title, invoiceData, shadow, chemicals }) {
  const [rows, setRows] = useState([]);
  useEffect(() => {
    const temprows = [];
    for (let i = 0; i < invoiceData.length; i++) {
      for (let j = 0; j < invoiceData[i].sprays?.length; j++) {
        for (let k = 0; k < invoiceData[i].sprays[j].chemicals?.length; k++) {
          const spray = invoiceData[i].sprays[j];
          const chem = invoiceData[i].sprays[j].chemicals[k];
          temprows.push({
            unBilled: invoiceData[i].sprays[j].chemicals[k].unBilled,
            id: `${i}${j}${k}`,
            orchard: k === 0 && j === 0 ? invoiceData[i].siteName : "--",
            date: k === 0 ? TimestamptoDate(invoiceData[i].sprays[j].sprayDate) : "--",
            chemicalName: invoiceData[i].sprays[j].chemicals[k].chemicalName,
            totalused: totalChemical(
              chem.sprayRate,
              chem.measure,
              spray.hectares,
              chem.unit,
              spray.waterRate,
              spray.tankCapacity,
              chem.actualChemical
            ).text,
            cost: `$${chemicals.chemicals.filter((c) => c.key === chem.key)[0].cost} per Unit`,
            chemicalcost: `$${(
              totalChemical(
                chem.sprayRate,
                chem.measure,
                spray.hectares,
                chem.unit,
                spray.waterRate,
                spray.tankCapacity,
                chem.actualChemical
              ).number * chemicals.chemicals.filter((c) => c.key === chem.key)[0].cost
            ).toFixed(2)}`,
            hectares: k === 0 ? spray.hectares : "--",
            spraycost: k === 0 ? `$${totalSprayCost(spray, chemicals).toFixed(2)}` : "--",
            orchardcost:
              k === 0 && j === 0
                ? `$${totalOrchardCost(invoiceData, i, chemicals).toFixed(2)}`
                : "--",
            percent:
              k === 0 && j === 0
                ? `$${(totalOrchardCost(invoiceData, i, chemicals) * 0.1).toFixed(2)}`
                : "--",
            billablecost:
              k === 0 && j === 0
                ? `$${(totalOrchardCost(invoiceData, i, chemicals) * 1.1).toFixed(2)}`
                : "--",
          });
        }
      }
    }
    setRows(temprows);
  }, [invoiceData]);

  const columns = [
    { field: "orchard", headerName: "Orchard", width: 200 },
    { field: "date", headerName: "Date", width: 150 },
    { field: "chemicalName", headerName: "Chemical", width: 150 },
    { field: "totalused", headerName: "Total Used", width: 130 },
    { field: "cost", headerName: "Cost", width: 150 },
    { field: "chemicalcost", headerName: "Chem Cost", width: 100 },
    { field: "hectares", headerName: "Ha", width: 60 },
    { field: "spraycost", headerName: "Spray Cost", width: 100 },
    { field: "orchardcost", headerName: "Total Cost", width: 100 },
    { field: "percent", headerName: "10%", width: 100 },
    { field: "billablecost", headerName: "Billable", width: 100 },
  ];

  const getCellClassName = (params) => {
    const { field, row } = params;
    if (
      ["chemicalName", "totalused", "cost", "chemicalcost", "hectares", "spraycost"].includes(
        field
      ) &&
      row.unBilled
    ) {
      return "unbilled-cell";
    }
    return "";
  };

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={4} px={2} pb={3}>
        <MDTypography variant="h4" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <DataGrid
        rows={rows}
        columns={columns}
        getCellClassName={getCellClassName}
        slots={{ toolbar: GridToolbar }}
      >
        {" "}
      </DataGrid>
    </Card>
  );
}

// Setting default props for the ProfilesList
InvoiceSprayListGrid.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
InvoiceSprayListGrid.propTypes = {
  title: PropTypes.string.isRequired,
  shadow: PropTypes.bool,
  invoiceData: PropTypes.objectOf.isRequired,
  chemicals: PropTypes.objectOf.isRequired,
};

export default InvoiceSprayListGrid;
