// react-routers components
// import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// React
// import { useEffect, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import ChemicalTable from "examples/Tables/ChemicalTable";

// firebase functions
// import {
//   getDoc,
//   doc,
//   setDoc,
//   Timestamp,
//   setDoc,
//   doc,
// } from "firebase/firestore";
import { TableContainer } from "@mui/material";

function BlockListEdit({ title, sprayInfo, shadow, setSprayInfo }) {
  const handleAddBlock = (block) => {
    setSprayInfo((prev) => {
      const newSpray = { ...prev };
      const newArray = [...prev.blockVarieties]; // make a copy of the original array
      const blockindex = prev.blockVarieties.findIndex((i) => i.block.code === block.block.code);

      newArray[blockindex].block.checked = !prev.blockVarieties[blockindex].block.checked; // update the object at the specified index
      newSpray.blockVarieties = newArray;

      // Calculate Hectares
      let sum = 0;
      /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
      for (let i = 0; i < newArray.length; i++) {
        for (let j = 0; j < newArray[i].block.varieties.length; j++) {
          if (newArray[i].block.varieties[j].checked && newArray[i].block.checked) {
            sum += Number(newArray[i].block.varieties[j].hectares);
          }
        }
      }
      const hec = Math.round(sum * 100) / 100;

      newSpray.hectares = hec;
      return newSpray; // return the new array
    });
  };

  const handleAddVariety = (block, variety) => {
    setSprayInfo((prev) => {
      const newSpray = { ...prev };
      const newArray = [...prev.blockVarieties]; // make a copy of the original array
      const blockindex = prev.blockVarieties.findIndex((i) => i.block.code === block.block.code);
      const varietyindex = prev.blockVarieties[blockindex].block.varieties.findIndex(
        (i) => i.key === variety.key
      );
      newArray[blockindex].block.varieties[varietyindex].checked =
        !prev.blockVarieties[blockindex].block.varieties[varietyindex].checked; // update the object at the specified index
      newSpray.blockVarieties = newArray;

      // Calculate Hectares
      let sum = 0;
      /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
      for (let i = 0; i < newArray.length; i++) {
        for (let j = 0; j < newArray[i].block.varieties.length; j++) {
          if (newArray[i].block.varieties[j].checked && newArray[i].block.checked) {
            sum += Number(newArray[i].block.varieties[j].hectares);
          }
        }
      }
      const hec = Math.round(sum * 100) / 100;

      newSpray.hectares = hec;
      return newSpray; // return the new array
    });
  };

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none", width: "100%" }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <TableContainer>
        <Table aria-label="simple table">
          <TableRow component="th">
            <TableCell sx={{ fontWeight: "bold" }} align="left">
              Block
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              Variety/Hectares
            </TableCell>
          </TableRow>
          <TableBody>
            {sprayInfo.blockVarieties.map((row, index) => (
              <TableRow
                key={row.key}
                align="right"
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  backgroundColor: index % 2 === 0 ? "#b2d6bb" : "#e3fad4",
                }}
              >
                <TableCell align="left">
                  {row.block.code}
                  <Checkbox checked={row.block.checked} onChange={() => handleAddBlock(row)} />
                </TableCell>

                {sprayInfo.blockVarieties[index].block.varieties.map((variety) => (
                  <TableRow
                    key={variety.key}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    {row.block.checked && (
                      <MDBox>
                        {" "}
                        <TableCell align="center">{variety.variety}</TableCell>
                        <TableCell align="center">{variety.hectares}</TableCell>
                        <TableCell align="center">
                          <Checkbox
                            checked={variety.checked}
                            onChange={() => handleAddVariety(row, variety)}
                          />
                        </TableCell>
                      </MDBox>
                    )}
                  </TableRow>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

// Setting default props for the ProfilesList
BlockListEdit.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
BlockListEdit.propTypes = {
  title: PropTypes.string.isRequired,
  setSprayInfo: PropTypes.func.isRequired,
  shadow: PropTypes.bool,
  sprayInfo: PropTypes.objectOf.isRequired,
};

export default BlockListEdit;
