// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// DatePicker components
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
// import MenuItem from "@mui/material/MenuItem";
// import InputLabel from "@mui/material/InputLabel";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// firebase functions
// import { db } from "firebaseDb";
// import { getDocs, query, collection, orderBy, where } from "firebase/firestore";

require("dayjs/locale/en-au");

// Setting Locale
dayjs.locale("en-au");

function InvoiceCard({
  title,
  info,
  shadow,
  // setInvoiceData,
  setEndDate,
  setStartDate,
}) {
  const labels = [];
  const values = [];

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label) => {
    if (label === "start") {
      return (
        <MDBox key={label} display="flex" py={1} pr={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="START DATE"
              value={info.start}
              onChange={(newDate) => {
                setStartDate(newDate);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </MDBox>
      );
    }
    if (label === "end") {
      return (
        <MDBox key={label} display="flex" py={1} pr={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="END DATE"
              value={info.end}
              onChange={(newDate) => {
                setEndDate(newDate);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </MDBox>
      );
    }

    return null;
  });

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none", backgroundColor: "#b2d6bb" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
          sx={{ fontSize: "1rem" }}
        >
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox opacity={0.3}>
          <Divider sx={{ background: "black" }} />
        </MDBox>
        <MDBox>{renderItems}</MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props
InvoiceCard.defaultProps = {
  shadow: true,
};

// Typechecking props
InvoiceCard.propTypes = {
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  info: PropTypes.any.isRequired,
  shadow: PropTypes.bool,
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
  setInvoiceData: PropTypes.func.isRequired,
};

export default InvoiceCard;
