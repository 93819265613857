// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// React
import { useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// firebase functions
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../../firebaseDb";

function Invoice({ date, id, noGutter }) {
  const [openDialog, setOpenDialog] = useState(false);

  const navigate = useNavigate();

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const archiveInvoice = () => {
    const invoiceRef = doc(db, "Invoices", id);
    setDoc(invoiceRef, { archive: true }, { merge: true });
  };

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      pr={1}
      mb={noGutter ? 0 : 1}
      sx={{ cursor: "pointer" }}
    >
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Record?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this invoice?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCloseDialog}>No</MDButton>
          <MDButton
            onClick={() => {
              archiveInvoice();
            }}
            autoFocus
          >
            Yes
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDBox
        lineHeight={1.125}
        onClick={() => {
          navigate(`/billing/${id}`);
        }}
      >
        <MDTypography display="block" variant="button" fontWeight="medium">
          {date}
        </MDTypography>
        <MDTypography variant="caption" fontWeight="regular" color="text">
          {id}
        </MDTypography>
      </MDBox>
      <MDBox
        display="flex"
        alignItems="center"
        onClick={() => {
          navigate(`/billing/${id}/grid`);
        }}
      >
        <MDBox display="flex" alignItems="center" lineHeight={1} ml={3} sx={{ cursor: "pointer" }}>
          <Icon fontSize="small">file_download</Icon>
          <MDTypography variant="button" fontWeight="bold">
            &nbsp;CSV
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox
        display="flex"
        alignItems="center"
        onClick={() => {
          handleOpenDialog(id);
        }}
      >
        <MDBox display="flex" alignItems="center" lineHeight={1} ml={3} sx={{ cursor: "pointer" }}>
          <Icon fontSize="small" color="red">
            delete
          </Icon>
          <MDTypography variant="button" fontWeight="bold">
            &nbsp;Archive Invoice
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Invoice
Invoice.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Invoice
Invoice.propTypes = {
  date: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default Invoice;
