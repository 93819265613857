// @mui material components
import Card from "@mui/material/Card";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Billing page components
import Invoice from "layouts/billing/components/Invoice";

// import { Timestamp } from "firebase/firestore";

function Invoices({ chemicals, units, invoices }) {
  const TimestamptoDate = (timestamp) => {
    const { seconds } = timestamp;
    const { nanoseconds } = timestamp;

    const milliseconds = seconds * 1000 + nanoseconds / 1e6;
    const date = new Date(milliseconds).toDateString();
    return date;
  };

  const navigate = useNavigate();
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Invoices
        </MDTypography>

        <MDButton
          variant="outlined"
          color="info"
          size="small"
          onClick={() => {
            navigate("/billing/createinvoice", {
              state: {
                data: { chemicals, units },
              },
            });
          }}
        >
          Create New Invoice
        </MDButton>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {invoices
            .sort((a, b) => b.endDate.seconds - a.endDate.seconds)
            .map(
              (invoice) =>
                !invoice.archive && (
                  <Invoice
                    key={invoice.key}
                    date={`${TimestamptoDate(invoice.startDate)} - ${TimestamptoDate(
                      invoice.endDate
                    )}`}
                    id={invoice.key}
                  />
                )
            )}
        </MDBox>
      </MDBox>
    </Card>
  );
}
// Typechecking props
Invoices.propTypes = {
  chemicals: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  units: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  invoices: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};
export default Invoices;
