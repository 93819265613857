// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function SelectedData({ info, setRow, row }) {
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox color="text" px={2}>
          {row.index > 0 && (
            <Icon
              sx={{ cursor: "pointer", fontWeight: "bold" }}
              fontSize="large"
              onClick={() => {
                if (row.index > 0) {
                  setRow((prevState) => ({
                    index: prevState.index - 1,
                    variety: info[prevState.index - 1].variety,
                    orchard: info[prevState.index - 1].orchard,
                    date: info[prevState.index - 1].date,
                  }));
                }
              }}
            >
              arrow_left
            </Icon>
          )}
        </MDBox>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            {`${row.date}  -->  ${row.orchard} - ${row.variety}`}
          </MDTypography>
        </MDBox>
        <MDBox color="text" px={2}>
          {row.index < info.length - 1 && (
            <Icon
              sx={{ cursor: "pointer", fontWeight: "bold" }}
              fontSize="large"
              onClick={() => {
                if (row.index < info.length - 1) {
                  setRow((prevState) => ({
                    index: prevState.index + 1,
                    variety: info[prevState.index + 1].variety,
                    orchard: info[prevState.index + 1].orchard,
                    date: info[prevState.index + 1].date,
                  }));
                }
              }}
            >
              arrow_right
            </Icon>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Typechecking props
SelectedData.propTypes = {
  info: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  row: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  setRow: PropTypes.func.isRequired,
};

export default SelectedData;
