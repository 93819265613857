// react-routers components
// import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// React
// import { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import ChemicalTable from "examples/Tables/ChemicalTable";

// firebase functions
// import {
//   getDoc,
//   doc,
//   setDoc,
//   Timestamp,
//   setDoc,
//   doc,
// } from "firebase/firestore";
import { TableContainer } from "@mui/material";

function BlockList({ title, sprayInfo, shadow }) {
  const filteredBlocks = sprayInfo.blockVarieties.filter((b) => b.block.checked);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < filteredBlocks.length; i++) {
    filteredBlocks[i].block.varieties = filteredBlocks[i].block.varieties.filter((v) => v.checked);
  }
  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <TableContainer>
        <Table aria-label="simple table">
          <TableRow component="th">
            <TableCell sx={{ fontWeight: "bold" }} align="left">
              Block
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              Variety/Hectares
            </TableCell>
          </TableRow>
          <TableBody>
            {filteredBlocks.map((row, index) => (
              <TableRow
                key={row.key}
                align="center"
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  backgroundColor: index % 2 === 0 ? "#b2d6bb" : "#e3fad4",
                }}
              >
                <TableCell align="left">{row.block.code}</TableCell>
                {filteredBlocks[index].block.varieties.map((variety) => (
                  <TableRow
                    key={variety.key}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">{variety.variety}</TableCell>
                    <TableCell align="center">{variety.hectares}</TableCell>
                  </TableRow>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

// Setting default props for the ProfilesList
BlockList.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
BlockList.propTypes = {
  title: PropTypes.string.isRequired,
  shadow: PropTypes.bool,
  sprayInfo: PropTypes.objectOf.isRequired,
};

export default BlockList;
