/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import RowTable from "examples/Tables/RowTable";

function RowData(props) {
  const { rowInfo, employees } = props;
  const [menu, setMenu] = useState(null);
  const [rows, setRows] = useState([]);
  const [selectedrow, setRow] = useState({});
  const [openRowModal, setOpenRowModal] = useState(false);
  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const handleOpenRowModal = () => setOpenRowModal(true);
  const handleCloseRowModal = () => setOpenRowModal(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const columns = [
    { Header: "row", accessor: "rowID", width: "20%", align: "left" },
    { Header: "finished", accessor: "finished", align: "center" },
    { Header: "total trees", accessor: "numTreesPerRow", width: "10%", align: "left" },
    { Header: "variety", accessor: "variety", align: "center" },
  ];

  function countCompletedRows(row) {
    const completedRowsCount = row.trees.reduce((count, tree) => {
      if (tree.prunedDate !== null) {
        const rowNumber = tree.treeNumber.slice(0, 3); // Assuming the row number is the first three characters of the tree number
        if (!count[rowNumber]) {
          count[rowNumber] = 1;
        } else {
          count[rowNumber]++;
        }
      }
      return count;
    }, {});

    const completedRows = Object.keys(completedRowsCount).length;

    return completedRows;
  }

  useEffect(() => {
    const updatedRows = rowInfo.map((row) => {
      const finished = countCompletedRows(row);
      return { ...row, finished };
    });

    setRows(updatedRows);
  }, [rowInfo]);

  useEffect(() => {
    console.log(selectedrow);
  }, [selectedrow]);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  const renderModal = (
    <Modal
      open={openRowModal}
      onClose={handleCloseRowModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MDBox sx={style}>
        <MDTypography id="modal-modal-title" variant="h6" component="h2">
          Assign trees from {selectedrow.rowID}
        </MDTypography>
        <FormControl size="large" sx={{ width: "100%", marginBottom: "10px" }}>
          <InputLabel id="orchard" sx={{ width: "100%" }}>
            Operators
          </InputLabel>
          <Select
            sx={{ height: "50px" }}
            labelId="operator-label"
            id="operator-select"
            value=""
            label="Operators"
          >
            {employees.map((op) => (
              <MenuItem key={op.key} value={op.FullName}>
                {op.FullName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </MDBox>
    </Modal>
  );
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Rows
          </MDTypography>
        </MDBox>
        <MDBox color="text" px={2}>
          <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
            more_vert
          </Icon>
        </MDBox>
        {renderMenu}
        {renderModal}
      </MDBox>
      <MDBox>
        <RowTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
          setRow={setRow}
          handleOpenRowModal={handleOpenRowModal}
        />
      </MDBox>
    </Card>
  );
}

export default RowData;
