import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Menu from "@mui/material/Menu";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import BlockListEdit from "examples/Lists/BlockListEdit";

// firebase functions
import { db } from "firebaseDb";
import { getDocs, query, collection, orderBy, where } from "firebase/firestore";

function Orchard({ spray, setSpray }) {
  const [orchards, setOrchards] = useState([]);
  const [dropdown, setDropdown] = useState(null);
  const [blockbg, setBlockBG] = useState("lightgrey");
  const [accordiantitle, setAccordianTitle] = useState("Block Info - No Blocks");
  // Styles
  const iconStyles = {
    ml: 1,
    fontWeight: "bold",
    transition: "transform 200ms ease-in-out",
  };
  const dropdownIconStyles = {
    transform: dropdown ? "rotate(180deg)" : "rotate(0)",
    ...iconStyles,
  };

  const openDropdown = ({ currentTarget }) => setDropdown(currentTarget);
  const closeDropdown = () => setDropdown(null);

  const handleSelectOrchard = (x) => {
    const blocksRef = collection(db, "Orchards");
    const q = query(blocksRef, where("siteCode", "==", x.siteCode));
    getDocs(q).then((querySnapshot) => {
      const tempblocks = [];
      querySnapshot.forEach((docu) => {
        if (
          !tempblocks.some((e) => e.block.code === docu.data().blockCode) &&
          docu.data().blockVarietyActive
        ) {
          tempblocks.push({
            key: docu.id,
            block: {
              code: docu.data().blockCode,
              varieties: [
                {
                  key: docu.id,
                  variety: docu.data().varietyName,
                  checked: true,
                  hectares: docu.data().hectares,
                },
              ],
              checked: true,
            },
          });
        } else {
          const index = tempblocks.findIndex((i) => i.block.code === docu.data().blockCode);
          if (docu.data().blockVarietyActive) {
            tempblocks[index].block.varieties.push({
              key: docu.id,
              variety: docu.data().varietyName,
              checked: true,
              hectares: docu.data().hectares,
            });
          }
        }
      });

      // Calculate Hectares
      let sum = 0;
      /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
      for (let i = 0; i < tempblocks.length; i++) {
        for (let j = 0; j < tempblocks[i].block.varieties.length; j++) {
          if (tempblocks[i].block.varieties[j].checked && tempblocks[i].block.checked) {
            sum += Number(tempblocks[i].block.varieties[j].hectares);
          }
        }
      }
      const hec = Math.round(sum * 100) / 100;
      console.log(tempblocks);
      setSpray((prevState) => ({
        ...prevState,
        hectares: hec,
        siteCode: x.siteCode,
        siteName: x.siteName,
        orchardGUID: x.orchardGUID,
        blockVarieties: tempblocks.sort((a, b) =>
          a.block.code.localeCompare(b.block.code, undefined, {
            numeric: true,
            sensitivity: "base",
          })
        ),
      }));
    });
    closeDropdown();
  };

  useEffect(() => {
    // Fetch Data
    const sprayRef = collection(db, "Orchards");
    const qs = query(sprayRef, orderBy("siteCode", "asc"));
    getDocs(qs).then((querySnapshot) => {
      const tempsites = [];
      querySnapshot.forEach((docu) => {
        if (docu.data().blockVarietyActive) {
          if (!tempsites.some((e) => e.siteCode === docu.data().siteCode)) {
            tempsites.push({
              key: docu.id,
              siteCode: docu.data().siteCode,
              siteName: docu.data().siteName,
              orchardGUID: docu.data().orchardGUID,
            });
          }
          if (tempsites.some((e) => e.siteCode === docu.data().siteCode)) {
            const index = tempsites.findIndex((e) => e.siteCode === docu.data().siteCode);
            if (!tempsites[index].orchardGUID) {
              tempsites[index].orchardGUID = docu.data().orchardGUID;
            }
          }
        }
      });
      setOrchards(tempsites);
    });
  }, []);

  function checkAllChecked(obj) {
    if (Array.isArray(obj)) {
      return obj.every((item) => checkAllChecked(item));
    }

    if (typeof obj === "object") {
      if ("checked" in obj) {
        if (!obj.checked) {
          return false;
        }
      }

      return Object.values(obj).every((value) => checkAllChecked(value));
    }

    return true;
  }

  useEffect(() => {
    if (spray.blockVarieties.length > 0) {
      if (checkAllChecked(spray.blockVarieties)) {
        setBlockBG("lightgreen");
        setAccordianTitle("Block Info - All Blocks Selected");
      } else {
        setBlockBG("lightyellow");
        setAccordianTitle("Block Info - Only Some Blocks Selected");
      }
    }
  }, [spray]);

  return (
    <MDBox mb={5}>
      <Grid item xs={12} md={12} lg={4}>
        <MDButton
          sx={{ minWidth: "300px", marginBottom: "15px", marginTop: "15px" }}
          variant="outlined"
          color="secondary"
          onClick={openDropdown}
        >
          <MDTypography>
            {`${spray.siteCode} - ${spray.siteName}`}{" "}
            <Icon sx={dropdownIconStyles}>expand_more</Icon>
          </MDTypography>
        </MDButton>

        <Menu anchorEl={dropdown} open={Boolean(dropdown)} onClose={closeDropdown}>
          {orchards.length > 0 ? (
            orchards.map((site) => (
              <MenuItem
                key={site.siteCode}
                onClick={() => {
                  handleSelectOrchard(site);
                }}
              >
                {`${site.siteCode} - ${site.siteName}`}
              </MenuItem>
            ))
          ) : (
            <CircularProgress color="success" />
          )}
        </Menu>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Accordion sx={{ backgroundColor: blockbg }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <MDTypography sx={{ paddingRight: "10px" }}>{accordiantitle}</MDTypography>
          </AccordionSummary>
          <AccordionDetails>
            <BlockListEdit sprayInfo={spray} title="" setSprayInfo={setSpray} />
          </AccordionDetails>
        </Accordion>
      </Grid>
    </MDBox>
  );
}

// Typechecking props
Orchard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  spray: PropTypes.object.isRequired,
  setSpray: PropTypes.func.isRequired,
};

export default Orchard;
