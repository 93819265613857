/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React from "react";
import { PDFViewer, Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// Images
import backGroundImage from "../../../../assets/images/logo-ct.png";
import signature from "../../../../assets/images/katesignature.png";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 12,
    padding: 30,
  },
  title: {
    fontSize: 16,
    marginBottom: 10,
    marginRight: 20,
  },
  date: {
    fontSize: 16,
    marginBottom: 10,
  },
  subheader: {
    width: "100%",
    fontSize: 14,
    marginBottom: 5,
    paddingTop: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth: 0,
    margin: "auto",
  },
  content: {
    fontSize: 14,
    marginBottom: 20,
  },
  sprayer: {
    fontSize: 14,
    marginBottom: 20,
    marginTop: 20,
    textDecoration: "underline",
  },
  settings: {
    fontSize: 12,
    marginBottom: 5,
  },
  image: {
    width: 40,
    marginBottom: 5,
    borderRadius: 10,
    maxHeight: 50,
  },
  signature: {
    width: 80,
    marginLeft: 20,
  },
  viewer: {
    width: "100%",
    height: 700,
    marginBottom: 20,
    borderRadius: 10,
  },
  header: {
    flexDirection: "row",
    paddingTop: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 5,
  },
  tableSignature: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderLeftWidth: 0,
    marginTop: 20,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableRowSignature: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColBlocks: {
    width: "14.2857%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColWeather: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColChem: {
    width: "16.6667%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColTanks: {
    width: "8.3333%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColSignature: {
    width: "33.33%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
  },
  tableSprayerRow: {
    margin: "auto",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  tableSprayerCol: {
    width: "33%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColHeader: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "black",
  },
  tableColHeaderBlocks: {
    width: "14.2857%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "black",
  },
  tableColHeaderChem: {
    width: "16.6667%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "grey",
    height: "40px",
  },
  tableColHeaderTanks: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "grey",
    height: "25px",
  },
  tableColHeaderPPE: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "white",
    height: "25px",
  },
  tableColHeaderWeather: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "lightgrey",
    height: "20px",
  },
  tableCell: {
    margin: "auto",
    marginTop: 4,
    fontSize: 10,
  },
  tableCellWeather: {
    margin: "auto",
    fontSize: 12,
  },
  tableCellHeader: {
    margin: "auto",
    fontSize: 12,
    color: "white",
    fontWeight: "bold",
  },
  tableCellHeaderBlocks: {
    margin: "auto",
    fontSize: 12,
    color: "white",
    fontWeight: "bold",
  },
  tableCellHeaderChem: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    color: "white",
    fontWeight: "bold",
  },
  tableCellHeaderTanks: {
    margin: "auto",
    marginTop: 5,
    fontSize: 14,
    color: "white",
    fontWeight: "bold",
  },
  tableCellHeaderPPE: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    color: "white",
    fontWeight: "bold",
  },
  tableCellPPE: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    fontWeight: "bold",
  },
  tableCellHeaderWeather: {
    margin: "auto",
    marginTop: 5,
    fontSize: 14,
  },
  column: {
    width: "33.33%",
    marginBottom: 10,
    paddingRight: 10,
  },
  cell: {
    marginBottom: 1,
  },
});

const PrintableReport = React.forwardRef(({ data, weather, chemInfo }, ref) => {
  // Filter for checked blocks
  const checkedBlocks = data.blockVarieties.filter((item) => item.block.checked);

  // Filter for checked varieties within each checked block
  const filteredData = checkedBlocks.map((item) => {
    item.block.varieties = item.block.varieties.filter((variety) => variety.checked);
    return item;
  });
  const totalWaterUsed = data.waterRate * data.hectares;
  const fullTanks = Math.floor(
    (data.sprayer[0].setting.sprayRate * data.hectares) / data.sprayer[0].tankCapacity
  );
  const partTank = (
    (data.sprayer[0].setting.sprayRate * data.hectares) %
    data.sprayer[0].tankCapacity
  ).toFixed(2);

  const perTankRate = (chem) => {
    if (fullTanks === 0) {
      return "--";
    }
    return `${(data.tankCapacity * (chem.sprayRate / data.waterRate)).toFixed(2)}  ${chem.unit}s`;
  };

  const perPartTankRate = (chem) =>
    `${((totalWaterUsed % data.tankCapacity) * (chem.sprayRate / data.waterRate)).toFixed(2)} ${
      chem.unit
    }s`;

  const totalChem = (chem) => `${(chem.sprayRate * data.hectares).toFixed(2)} ${chem.unit}s`;

  const weatherRows = {
    headers: [],
    values: [],
  };

  weather.forEach((item) => {
    weatherRows.headers.push(item.weatherType);
  });

  const maxLength = Math.max(...weather.map((item) => item.types.length));

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < maxLength; i++) {
    const valueObj = {};

    weather.forEach((item) => {
      valueObj[`${item.weatherType}Value`] = item.types[i] || "";
    });

    weatherRows.values.push(valueObj);
  }

  const rows = data.tanks.reduce((result, item, index) => {
    const rowIndex = Math.floor(index / 12);
    if (!result[rowIndex]) {
      result[rowIndex] = [];
    }
    result[rowIndex] = [...result[rowIndex], item]; // Use spread syntax to create a new array
    return result;
  }, []);

  const lastRowIndex = rows.length - 1;
  const lastRow = rows[lastRowIndex];
  const remainingBlocks = 12 - lastRow.length;
  // Add blank objects to the last row if it's a partial row
  if (remainingBlocks > 0 && remainingBlocks < 12) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < remainingBlocks; i++) {
      lastRow.push({ title: "" });
    }
  }

  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View ref={ref}>
            <View style={styles.header}>
              <Image src={backGroundImage} style={styles.image} />
              <Text style={styles.title}>
                Spray Instruction - {data.siteName} {data.siteCode}
              </Text>
              <Text style={styles.date}>Instruction #: {data.sprayInstructionId}</Text>
            </View>
            <View style={styles.header}>
              <Text style={styles.subheader}>Sprayer Settings</Text>
            </View>
            <View style={styles.tableSprayerRow}>
              {data.sprayer.map((tractor) => (
                <View
                  style={{
                    ...styles.tableSprayerCol,
                    width: `${JSON.stringify(100 / (data.sprayer.length + 1))}%`,
                  }}
                >
                  <Text style={styles.sprayer}>{tractor.sprayer}</Text>
                  <Text style={styles.settings}>Setting: {tractor.setting.settingName}</Text>
                  <Text style={styles.settings}>Gear: {tractor.setting.gear}</Text>
                  <Text style={styles.settings}>Speed: {tractor.setting.speed} km/h</Text>
                  <Text style={styles.settings}>Pressure: {tractor.setting.pressure} psi</Text>
                  <Text style={styles.settings}>Fan: {tractor.setting.fan}</Text>
                </View>
              ))}

              <View
                style={{
                  ...styles.tableSprayerCol,
                  width: `${JSON.stringify(100 / (data.sprayer.length + 1))}%`,
                  backgroundColor: "lightblue",
                  paddingLeft: 6,
                  borderBottomLeftRadius: 5,
                  borderBottomRightRadius: 5,
                }}
              >
                <Text style={styles.sprayer}>Water Settings</Text>
                <Text style={styles.settings}>
                  Water Rate: {data.sprayer[0].setting.settingName}
                </Text>
                <Text style={styles.settings}>Water Capacity: {data.sprayer[0].tankCapacity}L</Text>
                <Text style={styles.settings}>Total Water: {totalWaterUsed} L</Text>
                <Text style={styles.settings}>
                  Full Tanks: {`${fullTanks} x ${data.sprayer[0].tankCapacity}L Tanks`}
                </Text>
                <Text style={{ ...styles.settings, fontSize: 14 }}>Part Tank: {partTank} L</Text>
              </View>
            </View>
            <View
              style={{
                ...styles.tableSprayerCol,
                width: `100%`,
                backgroundColor: "lightgrey",
                paddingLeft: 6,
                paddingTop: 6,
                marginTop: 6,
                borderRadius: 5,
              }}
            >
              <Text style={styles.title}>Notes - {data.notes}</Text>
            </View>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeaderChem}>
                  <Text style={styles.tableCellHeaderChem}>Chemical</Text>
                  <Text style={styles.tableCellHeaderChem}>(Active Ingredient)</Text>
                </View>
                <View style={styles.tableColHeaderChem}>
                  <Text style={styles.tableCellHeaderChem}>Usage Rate</Text>
                  <Text style={styles.tableCellHeaderChem}>(per Ha)</Text>
                </View>
                <View style={styles.tableColHeaderChem}>
                  <Text style={styles.tableCellHeaderChem}>Usage Rate</Text>
                  <Text style={styles.tableCellHeaderChem}>(per Tank)</Text>
                </View>
                <View style={styles.tableColHeaderChem}>
                  <Text style={styles.tableCellHeaderChem}>Part Tank Amount</Text>
                </View>
                <View style={styles.tableColHeaderChem}>
                  <Text style={styles.tableCellHeaderChem}>Total Planned</Text>
                </View>
                <View style={styles.tableColHeaderChem}>
                  <Text style={styles.tableCellHeaderChem}>Total Actual</Text>
                </View>
              </View>
              {data?.chemicals?.map((chem) => (
                <View>
                  <View style={{ ...styles.tableRow, height: "20px" }}>
                    <View
                      style={{
                        ...styles.tableColChem,
                      }}
                    >
                      <Text
                        style={{
                          ...styles.tableCell,
                        }}
                      >
                        {chem.chemicalName}
                      </Text>
                    </View>
                    <View
                      style={{
                        ...styles.tableColChem,
                      }}
                    >
                      <Text style={styles.tableCell}>
                        {`${chem.sprayRate} `}
                        {`${chem.unit}s`}
                      </Text>
                    </View>
                    <View
                      style={{
                        ...styles.tableColChem,
                      }}
                    >
                      <Text style={styles.tableCell}>{`${perTankRate(chem)} `}</Text>
                    </View>
                    <View
                      style={{
                        ...styles.tableColChem,
                      }}
                    >
                      <Text style={styles.tableCell}>{perPartTankRate(chem)}</Text>
                    </View>
                    <View
                      style={{
                        ...styles.tableColChem,
                      }}
                    >
                      <Text style={styles.tableCell}>{totalChem(chem)}</Text>
                    </View>
                    <View
                      style={{
                        ...styles.tableColChem,
                      }}
                    />
                  </View>
                  <View style={{ ...styles.tableRow }}>
                    <View
                      style={{
                        ...styles.tableColChem,
                        backgroundColor: "lightgrey",
                      }}
                    >
                      <Text
                        style={{
                          ...styles.tableCell,
                          fontSize: 8,
                        }}
                      >
                        ({" "}
                        {
                          chemInfo?.filter(
                            (chemical) => chemical.chemicalName === chem.chemicalName
                          )[0]?.activeIngredient
                        }
                        )
                      </Text>
                    </View>
                    <View
                      style={{
                        ...styles.tableColChem,
                        width: "83.33%",
                      }}
                    >
                      <Text
                        style={{
                          ...styles.tableCell,
                          fontSize: 12,
                          margin: 6,
                        }}
                      >
                        Batch No:
                      </Text>
                    </View>
                  </View>
                </View>
              ))}
            </View>

            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeaderTanks}>
                  <Text style={styles.tableCellHeaderTanks}>Tanks</Text>
                </View>
              </View>
              {rows.map((row) => (
                <View key={row[0].key}>
                  <View style={{ ...styles.tableRow, height: "30px", alignContent: "center" }}>
                    {row.map((tank) => (
                      <View
                        style={{
                          ...styles.tableColTanks,
                        }}
                      >
                        <Text
                          style={{
                            ...styles.tableCell,
                            fontSize: 10,
                            paddingTop: 5,
                          }}
                        >
                          {tank.title}
                        </Text>
                      </View>
                    ))}
                  </View>
                </View>
              ))}
            </View>

            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeaderTanks}>
                  <Text style={styles.tableCellHeaderTanks}>
                    Weather - Circle one in each column
                  </Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.tableRow,
                  alignContent: "center",
                }}
              >
                {weatherRows.headers.map((item) => (
                  <View
                    key={item.key}
                    style={{
                      ...styles.tableColHeaderWeather,
                      width: `${100 / weather.length}%`,
                    }}
                  >
                    <Text style={styles.header}>{item.toUpperCase()}</Text>
                  </View>
                ))}
              </View>
              {weatherRows.values.map((item) => (
                <View
                  style={{
                    ...styles.tableRow,
                    height: "20px",
                    alignContent: "center",
                  }}
                >
                  <View
                    key={item.key}
                    style={{ ...styles.tableColWeather, width: `${100 / weather.length}%` }}
                  >
                    <Text style={styles.tableCellWeather}>{item.windValue}</Text>
                  </View>
                  <View
                    key={item.key}
                    style={{ ...styles.tableColWeather, width: `${100 / weather.length}%` }}
                  >
                    <Text style={styles.tableCellWeather}>{item.sunValue}</Text>
                  </View>
                  <View
                    key={item.key}
                    style={{ ...styles.tableColWeather, width: `${100 / weather.length}%` }}
                  >
                    <Text style={styles.tableCellWeather}>{item.temperatureValue}</Text>
                  </View>
                </View>
              ))}
            </View>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeaderTanks}>
                  <Text style={styles.tableCellHeaderPPE}>
                    Handling Precautions: Please select below which protective equipment was worn
                    while completing this spray application.
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeaderPPE}>
                  <Text style={styles.tableCellPPE}>Full PPE</Text>
                </View>
                <View style={styles.tableColHeaderPPE}>
                  <Text style={styles.tableCellPPE}>Spray Suit</Text>
                </View>
                <View style={styles.tableColHeaderPPE}>
                  <Text style={styles.tableCellPPE}>Gloves</Text>
                </View>
                <View style={styles.tableColHeaderPPE}>
                  <Text style={styles.tableCellPPE}>Googles/Face Shield</Text>
                </View>
                <View style={styles.tableColHeaderPPE}>
                  <Text style={styles.tableCellPPE}>Respirator</Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
        <Page size="A4" style={styles.page}>
          <View style={styles.page}>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeaderBlocks}>
                  <Text style={styles.tableCellHeader}>Block</Text>
                </View>
                <View style={styles.tableColHeaderBlocks}>
                  <Text style={styles.tableCellHeader}>Variety</Text>
                </View>
                <View style={styles.tableColHeaderBlocks}>
                  <Text style={styles.tableCellHeader}>Hectares</Text>
                </View>
                <View style={styles.tableColHeaderBlocks}>
                  <Text style={styles.tableCellHeader}>Tanks</Text>
                </View>
                <View style={styles.tableColHeaderBlocks}>
                  <Text style={styles.tableCellHeader}>Checked</Text>
                </View>
                <View style={styles.tableColHeaderBlocks}>
                  <Text style={styles.tableCellHeader}>Start Time</Text>
                </View>
                <View style={styles.tableColHeaderBlocks}>
                  <Text style={styles.tableCellHeader}>End Time</Text>
                </View>
              </View>
              {filteredData.map((block) =>
                block.block.varieties.map((variety, index) => (
                  <View style={{ ...styles.tableRow }}>
                    <View
                      style={{
                        ...styles.tableColBlocks,
                        borderBottomWidth: index === block.block.varieties.length - 1 ? 3 : 0,
                      }}
                    >
                      <Text
                        style={{
                          ...styles.tableCell,
                          top: 8 * (block.block.varieties.length - 1),
                          fontSize: 12,
                        }}
                      >
                        {index === 0 ? block.block.code : ""}
                      </Text>
                    </View>
                    <View
                      style={{
                        ...styles.tableColBlocks,
                        borderBottomWidth: index === block.block.varieties.length - 1 ? 3 : 1,
                      }}
                    >
                      <Text style={styles.tableCell}>{variety.variety}</Text>
                    </View>
                    <View
                      style={{
                        ...styles.tableColBlocks,
                        borderBottomWidth: index === block.block.varieties.length - 1 ? 3 : 1,
                      }}
                    >
                      <Text style={styles.tableCell}>{variety.hectares}</Text>
                    </View>
                    <View
                      style={{
                        ...styles.tableColBlocks,
                        borderBottomWidth: index === block.block.varieties.length - 1 ? 3 : 1,
                      }}
                    >
                      <Text style={styles.tableCell}>
                        {(
                          (data.sprayer[0].setting.sprayRate * variety.hectares) /
                          data.sprayer[0].tankCapacity
                        ).toFixed(2)}
                      </Text>
                    </View>
                    <View
                      style={{
                        ...styles.tableColBlocks,
                        borderBottomWidth: index === block.block.varieties.length - 1 ? 3 : 1,
                      }}
                    >
                      <Text style={styles.tableCell} />
                    </View>
                    <View
                      style={{
                        ...styles.tableColBlocks,
                        borderBottomWidth: index === block.block.varieties.length - 1 ? 3 : 1,
                      }}
                    >
                      <Text style={styles.tableCell} />
                    </View>
                    <View
                      style={{
                        ...styles.tableColBlocks,
                        borderBottomWidth: index === block.block.varieties.length - 1 ? 3 : 1,
                      }}
                    >
                      <Text style={styles.tableCell} />
                    </View>
                  </View>
                ))
              )}
              <View style={styles.tableRow}>
                <View style={styles.tableColHeaderBlocks}>
                  <Text style={styles.tableCellHeader}>Totals</Text>
                </View>
                <View style={styles.tableColHeaderBlocks}>
                  <Text style={styles.tableCellHeader} />
                </View>
                <View style={styles.tableColHeaderBlocks}>
                  <Text style={styles.tableCellHeader}>{data.hectares} Hectares</Text>
                </View>
                <View style={styles.tableColHeaderBlocks}>
                  <Text style={styles.tableCellHeader}>
                    {" "}
                    {(
                      (data.sprayer[0].setting.sprayRate * data.hectares) /
                      data.sprayer[0].tankCapacity
                    ).toFixed(2)}{" "}
                    Tanks
                  </Text>
                </View>
                <View style={styles.tableColHeaderBlocks}>
                  <Text style={styles.tableCellHeader} />
                </View>
                <View style={styles.tableColHeaderBlocks}>
                  <Text style={styles.tableCellHeader} />
                </View>
                <View style={styles.tableColHeaderBlocks}>
                  <Text style={styles.tableCellHeader} />
                </View>
              </View>
            </View>
            <View style={styles.tableSignature}>
              <View style={{ ...styles.tableRow, marginLeft: 2, marginTop: 4, marginBottom: 4 }}>
                <View style={styles.tableColHeaderSignature}>
                  <Text style={styles.tableCellHeaderSignature}>Operator(s):</Text>
                </View>
              </View>
              {data?.operatorWorker?.map((op) => (
                <View style={{ ...styles.tableRow, marginTop: 15 }}>
                  <View
                    style={{
                      ...styles.tableColSignature,
                    }}
                  >
                    <Text style={styles.tableCell}>{op.toUpperCase()}</Text>
                  </View>
                  <View
                    style={{
                      ...styles.tableColSignature,
                      borderBottomWidth: 0,
                    }}
                  >
                    <Text style={styles.tableCell}>Date:________________ </Text>
                  </View>
                  <View
                    style={{
                      ...styles.tableColSignature,
                      borderBottomWidth: 0,
                    }}
                  >
                    <Text style={styles.tableCell}>Signature:________________</Text>
                  </View>
                </View>
              ))}
              <View style={{ ...styles.tableRow, marginTop: 15 }}>
                <View
                  style={{
                    ...styles.tableColSignature,
                  }}
                >
                  <Text style={styles.tableCell}>AUTHORISED BY: KATE THOMAS</Text>
                </View>
                <View
                  style={{
                    ...styles.tableColSignature,
                    borderBottomWidth: 0,
                    marginRight: 0,
                  }}
                >
                  <Text style={{ ...styles.tableCell, marginRight: 0 }}>Signature:</Text>
                </View>
                <View
                  style={{
                    ...styles.tableColSignature,
                    borderBottomWidth: 1,
                    marginLeft: 5,
                  }}
                >
                  <Image src={signature} style={styles.signature} />
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
});

// Typechecking props
PrintableReport.propTypes = {
  data: PropTypes.objectOf.isRequired,
  weather: PropTypes.objectOf.isRequired,
};

export default PrintableReport;
