// @mui material components
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
// import Icon from "@mui/material/Icon";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Overview page components
import Header from "layouts/spraydetail/components/Header";
import PrintableReport from "layouts/weedsprays/weedsprayPDF/components/PDFReport";

// React
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

// Firebase
import { collection, where, query, onSnapshot } from "firebase/firestore";
import { db } from "firebaseDb";

function Overview() {
  const { sprayId } = useParams();
  const [sprayInfo, setSprayInfo] = useState({});
  const [weather, setWeather] = useState([]);
  const [chemInfo, setChemInfo] = useState([]);
  const printableComponentRef = useRef();

  // Get Spray Data
  useEffect(() => {
    const sprayRef = collection(db, "Weedsprays");
    const q = query(sprayRef, where("sprayInstructionId", "==", sprayId));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.forEach((docu) => {
        setSprayInfo({ ...docu.data(), key: docu.id });
      });
    });

    const tempWeather = [];
    const weatherRef = collection(db, "Weather");
    const unsubscribe2 = onSnapshot(weatherRef, (snapshot) => {
      snapshot.forEach((docu) => {
        tempWeather.push({ ...docu.data(), key: docu.id });
      });
      setWeather(tempWeather);
    });

    const tempChemicals = [];
    const chemRef = collection(db, "Chemicals");
    const unsubscribe3 = onSnapshot(chemRef, (snapshot) => {
      snapshot.forEach((docu) => {
        tempChemicals.push({ ...docu.data(), key: docu.id });
      });
      setChemInfo(tempChemicals);
    });

    return () => {
      unsubscribe();
      unsubscribe2();
      unsubscribe3();
    };
  }, []);

  if (sprayInfo.orchards) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <PrintableReport
          ref={printableComponentRef}
          data={sprayInfo}
          weather={weather}
          chemInfo={chemInfo}
        />
        <Footer />
      </DashboardLayout>
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <Box mt={5} mb={3} textAlign="center">
          <CircularProgress color="success" />
        </Box>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
