// react-routers components
// import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// React
import { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import ChemicalTable from "examples/Tables/ChemicalTable";

// firebase functions
import { collection, query, getDocs, orderBy } from "firebase/firestore";
import { TableContainer } from "@mui/material";
import { db } from "../../../firebaseDb";

const perTank = (amount, measure, unit, waterRate, tankCapacity) => {
  const tanksperHectare = waterRate / tankCapacity;
  if (measure === "Tank") {
    return `${amount} ${unit}`;
  }
  if (measure === "Hectare") {
    return `${Math.round((100 * Number(amount)) / tanksperHectare) / 100} ${unit}`;
  }
  if (measure === "1000 L") {
    return `${Math.round((100 * (Number(amount) * tankCapacity)) / 1000) / 100} ${unit}`;
  }
  return null;
};

const perPartTank = (amount, measure, hectares, unit, waterRate, tankCapacity) => {
  const tanksperHectare = waterRate / tankCapacity;
  const fraction =
    (Number(hectares) * Number(waterRate) -
      Math.floor((Number(hectares) * Number(waterRate)) / tankCapacity) * tankCapacity) /
    tankCapacity;

  if (measure === "Tank") {
    return `${Math.round(100 * amount * fraction) / 100} ${unit}`;
  }
  if (measure === "Hectare") {
    return `${Math.round((100 * (Number(amount) * fraction)) / tanksperHectare) / 100} ${unit}`;
  }
  if (measure === "1000 L") {
    return `${Math.round((100 * (Number(amount) * tankCapacity * fraction)) / 1000) / 100} ${unit}`;
  }
  return null;
};

const totalChemical = (amount, measure, hectares, unit, waterRate, tankCapacity) => {
  const tanksperHectare = waterRate / tankCapacity;
  if (measure === "Tank") {
    return `${Math.round(100 * amount * tanksperHectare * hectares) / 100} ${unit}`;
  }
  if (measure === "Hectare") {
    return `${Math.round(100 * Number(amount) * hectares) / 100} ${unit}`;
  }
  if (measure === "1000 L") {
    return `${Math.round((100 * Number(amount) * waterRate * hectares) / 1000) / 100} ${unit}`;
  }
  return null;
};

function ChemicalsList({ title, sprayInfo, shadow, setSprayInfo }) {
  const [units, setUnits] = useState([]);
  const [measures, setMeasures] = useState([]);
  const [chemicalList, setChemicals] = useState(sprayInfo.chemicals);
  const [chemicals, setChemicalList] = useState([]);

  const handleSelectUnit = (x, index) => {
    setSprayInfo((prevState) => {
      const newState = { ...prevState };
      const newArray = newState.chemicals;
      newArray[index] = { ...newArray[index], unit: x }; // update the property of the object at the specified index
      newState.chemicals = newArray;
      return newState; // return the new array
    });
  };

  const handleSelectMeasure = (x, index) => {
    setSprayInfo((prevState) => {
      const newState = { ...prevState };
      const newArray = newState.chemicals;
      newArray[index] = { ...newArray[index], measure: x }; // update the property of the object at the specified index
      newState.chemicals = newArray;
      return newState; // return the new array
    });
  };

  const setBatch = (value, index) => {
    setChemicals((prevArray) => {
      const newArray = [...prevArray];
      newArray[index].batchNo = value;
      return newArray;
    });
  };

  useEffect(() => {
    const unitRef = collection(db, "Units");
    const q = query(unitRef, orderBy("unit", "asc"));
    getDocs(q).then((querySnapshot) => {
      const tempunits = [];
      querySnapshot.forEach((docu) => {
        tempunits.push({
          key: docu.id,
          unit: docu.data().unit,
        });
      });
      setUnits(tempunits);
    });
    const measureRef = collection(db, "Measures");
    const qm = query(measureRef, orderBy("measure", "asc"));
    getDocs(qm).then((querySnapshot) => {
      const tempmeasures = [];
      querySnapshot.forEach((docu) => {
        tempmeasures.push({
          key: docu.id,
          measure: docu.data().measure,
        });
      });
      setMeasures(tempmeasures);
    });

    const chemRef = collection(db, "Chemicals");
    const qc = query(chemRef, orderBy("chemicalName", "asc"));
    getDocs(qc).then((querySnapshot) => {
      const tempchems = [];
      querySnapshot.forEach((docu) => {
        tempchems.push({
          key: docu.id,
          chemicalName: docu.data().chemicalName,
          sprayRate: "",
          unit: "",
          measure: "",
        });
      });
      setChemicalList(tempchems);
    });
  }, []);

  const setSprayRate = (index, value) => {
    if (!Number.isNaN(+value)) {
      setSprayInfo((prevState) => {
        const newState = { ...prevState };
        const newArray = newState.chemicals;
        newArray[index] = { ...newArray[index], sprayRate: value }; // update the property of the object at the specified index
        newState.chemicals = newArray;
        return newState; // return the new array
      });
    }
  };

  const setActualChemical = (index, value) => {
    if (!Number.isNaN(+value)) {
      setSprayInfo((prevState) => {
        const newState = { ...prevState };
        const newArray = newState.chemicals;
        newArray[index] = { ...newArray[index], actualChemical: value }; // update the property of the object at the specified index
        newState.chemicals = newArray;
        return newState; // return the new array
      });
    }
  };

  const handleSelectChemical = (x, index) => {
    setSprayInfo((prevState) => {
      const newState = { ...prevState };
      const newArray = newState.chemicals;
      newArray[index] = { ...newArray[index], chemicalName: x.chemicalName }; // update the property of the object at the specified index
      newState.chemicals = newArray;
      return newState; // return the new array
    });
  };

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <TableContainer>
        <Table aria-label="simple table">
          <TableRow component="th">
            <TableCell sx={{ fontWeight: "bold" }} align="left">
              Chemical Name
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              Spray Rate
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              Unit
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              Measure
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              Per Full Tank
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              Per Part Tank
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              Total Chemical Used
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              Actual Chemical Used
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              Batch Number
            </TableCell>
          </TableRow>
          <TableBody>
            {chemicalList.map((row, index) => (
              <TableRow
                hover
                key={row.key}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">
                  {" "}
                  <Select
                    sx={{ height: "50px" }}
                    labelId="operator-label"
                    id="operator-select"
                    value={sprayInfo.chemicals.map((c) => c.chemicalName)[index]}
                    label="Chemical"
                  >
                    {chemicals.length > 0 ? (
                      chemicals.map((chem) => (
                        <MenuItem
                          key={chem.key}
                          value={chem.chemicalName}
                          onClick={() => {
                            handleSelectChemical(chem, index);
                          }}
                        >
                          {chem.chemicalName}
                        </MenuItem>
                      ))
                    ) : (
                      <CircularProgress color="success" />
                    )}
                  </Select>
                </TableCell>
                <TableCell align="center">
                  {" "}
                  <TextField
                    value={row.sprayRate}
                    label="Rate"
                    sx={{ fontSize: "1rem", minWidth: "50px" }}
                    onChange={(e) => {
                      setSprayRate(index, e.target.value);
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="orchard" sx={{ width: "100%" }}>
                      Unit
                    </InputLabel>
                    <Select
                      sx={{ height: "50px" }}
                      labelId="stage-label"
                      id="stages-select"
                      value={row.unit}
                      label="Stages"
                    >
                      {units.length > 0 ? (
                        units.map((unit) => (
                          <MenuItem
                            key={unit.key}
                            value={unit.unit}
                            onClick={() => {
                              handleSelectUnit(unit.unit, index);
                            }}
                          >
                            {`${unit.unit}s`}
                          </MenuItem>
                        ))
                      ) : (
                        <CircularProgress color="success" />
                      )}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell align="center">
                  <FormControl size="large" sx={{ width: "100%" }}>
                    <InputLabel id="unit" sx={{ width: "100%" }}>
                      Measures
                    </InputLabel>
                    <Select
                      sx={{ height: "50px" }}
                      labelId="operator-label"
                      id="operator-select"
                      value={row.measure}
                      label="Measure"
                    >
                      {measures.length > 0 ? (
                        measures.map((op) => (
                          <MenuItem
                            key={op.key}
                            value={op.measure}
                            onClick={() => {
                              handleSelectMeasure(op.measure, index);
                            }}
                          >
                            {`per ${op.measure}`}
                          </MenuItem>
                        ))
                      ) : (
                        <CircularProgress color="success" />
                      )}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell align="center">
                  {perTank(
                    row.sprayRate,
                    row.measure,
                    row.unit,
                    sprayInfo.waterRate,
                    sprayInfo.tankCapacity
                  )}
                </TableCell>
                <TableCell align="center">
                  {perPartTank(
                    row.sprayRate,
                    row.measure,
                    sprayInfo.hectares,
                    row.unit,
                    sprayInfo.waterRate,
                    sprayInfo.tankCapacity
                  )}
                </TableCell>
                <TableCell align="center">
                  {totalChemical(
                    row.sprayRate,
                    row.measure,
                    sprayInfo.hectares,
                    row.unit,
                    sprayInfo.waterRate,
                    sprayInfo.tankCapacity
                  )}
                </TableCell>
                <TableCell align="center">
                  <TextField
                    type="number"
                    value={row.actualChemical}
                    label="Actual Chemical"
                    sx={{ fontSize: "1rem", minWidth: "115px" }}
                    onChange={(e) => {
                      setActualChemical(index, e.target.value);
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
                    sx={{ minWidth: "110px" }}
                    value={row.batchNo}
                    onChange={(event) => setBatch(event.target.value, index)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

// Setting default props for the ProfilesList
ChemicalsList.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
ChemicalsList.propTypes = {
  title: PropTypes.string.isRequired,
  shadow: PropTypes.bool,
  sprayInfo: PropTypes.objectOf.isRequired,
  setSprayInfo: PropTypes.func.isRequired,
};

export default ChemicalsList;
