// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
// import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// firebase functions
// import { db } from "firebaseDb";
// import { getDocs, query, collection, orderBy, where } from "firebase/firestore";

function PurchaseCard({ title, shadow, data, setInvoiceData }) {
  const [chemical, setChemical] = useState("");
  const [cost, setCost] = useState("");
  const [amount, setAmount] = useState("");
  const [unit, setUnit] = useState("");

  const handleSelectChemical = (chem) => {
    setChemical(chem);
  };

  const handleSelectUnit = (x) => {
    setUnit(x);
  };

  const handleAddPurchase = () => {
    setInvoiceData((prev) => {
      const newData = { ...prev };
      const newArray = [...prev.purchases]; // make a copy of the original array

      newArray.push({ chemical, amount, unit, cost });
      newData.purchases = newArray;
      return newData; // return the new array
    });
  };
  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none", backgroundColor: "#FFF7D4" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
          sx={{ fontSize: "1rem" }}
        >
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox opacity={0.3}>
          <Divider sx={{ background: "black" }} />
        </MDBox>
        <MDBox>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={6}>
              <MDBox py={1} pr={2}>
                <FormControl size="large" sx={{ width: "100%", marginBottom: "10px" }}>
                  <InputLabel id="orchard" sx={{ width: "100%" }}>
                    Chemical
                  </InputLabel>
                  <Select
                    sx={{ height: "50px" }}
                    labelId="chemical-label"
                    id="chemical-select"
                    value={chemical}
                    label="Chemical"
                  >
                    {data.chemicals.map((op) => (
                      <MenuItem
                        key={op.key}
                        value={op.chemicalName}
                        onClick={() => {
                          handleSelectChemical(op.chemicalName);
                        }}
                      >
                        {op.chemicalName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <MDBox display="flex" py={1} pr={2}>
                <FormControl size="large" sx={{ width: "100%", marginBottom: "10px" }}>
                  <InputLabel id="orchard" sx={{ width: "100%" }}>
                    Units
                  </InputLabel>
                  <Select
                    sx={{ height: "50px" }}
                    labelId="unit-label"
                    id="unit-select"
                    value={unit}
                    label="Units"
                  >
                    {data.units.map((op) => (
                      <MenuItem
                        key={op.key}
                        value={op.unit}
                        onClick={() => {
                          handleSelectUnit(op.unit);
                        }}
                      >
                        {op.unit}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} xl={4}>
              <MDBox py={1} pr={2}>
                <TextField
                  value={amount}
                  label="Amount"
                  sx={{ fontSize: "1rem", width: "100%" }}
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={4} xl={4}>
              <MDBox py={1} pr={2}>
                <TextField
                  value={cost}
                  label="Cost ($)"
                  sx={{ fontSize: "1rem", width: "100%" }}
                  onChange={(e) => {
                    setCost(e.target.value);
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} xl={4}>
              <MDBox py={1} pr={2}>
                <MDButton
                  variant="gradient"
                  color="success"
                  sx={{ width: "100%" }}
                  onClick={() => {
                    handleAddPurchase();
                  }}
                >
                  Add Purchase
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props
PurchaseCard.defaultProps = {
  shadow: true,
};

// Typechecking props
PurchaseCard.propTypes = {
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
  shadow: PropTypes.bool,
  setInvoiceData: PropTypes.func.isRequired,
};

export default PurchaseCard;
