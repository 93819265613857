import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Billing page components
import Invoices from "layouts/billing/components/Invoices";
// import SpraysCosts from "layouts/billing/components/SpraysCost";

// firebase functions
import { db } from "firebaseDb";
// import { getDocs, query, collection, orderBy } from "firebase/firestore";
import { onSnapshot, collection, query, orderBy } from "firebase/firestore";
import { CircularProgress } from "@mui/material";

function Billing() {
  const [chemicals, setChemicals] = useState([]);
  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);

  const TimestamptoDate = (timestamp) => {
    const { seconds } = timestamp;
    const { nanoseconds } = timestamp;

    const milliseconds = seconds * 1000 + nanoseconds / 1e6;
    const date = new Date(milliseconds).toDateString();
    return date;
  };

  useEffect(() => {
    const chemRef = collection(db, "Chemicals");
    const chemsQuery = query(chemRef, orderBy("chemicalName", "asc"));

    const unsubscribeChemicals = onSnapshot(chemsQuery, (querySnapshot) => {
      const tempchems = [];
      querySnapshot.forEach((docu) => {
        tempchems.push({ ...docu.data(), key: docu.id });
      });
      setChemicals(tempchems);
      setLoading(false);
    });

    const unitRef = collection(db, "Units");
    const unitsQuery = query(unitRef);

    const unsubscribeUnits = onSnapshot(unitsQuery, (querySnapshot) => {
      const tempunits = [];
      querySnapshot.forEach((docu) => {
        tempunits.push({
          key: docu.id,
          unit: docu.data().unit,
        });
      });
      setUnits(tempunits);
    });

    const invoiceRef = collection(db, "Invoices");
    const invoicesQuery = query(invoiceRef);

    const unsubscribeInvoices = onSnapshot(invoicesQuery, (querySnapshot) => {
      const tempinvoices = [];
      querySnapshot.forEach((docu) => {
        tempinvoices.push({
          ...docu.data(),
          key: docu.id,
        });
      });
      tempinvoices.sort((a, b) => TimestamptoDate(b.startDate) - TimestamptoDate(a.startDate));
      setInvoices(tempinvoices);
    });

    return () => {
      // Unsubscribe listeners when the component unmounts
      unsubscribeChemicals();
      unsubscribeUnits();
      unsubscribeInvoices();
    };
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              {!loading && <Invoices chemicals={chemicals} units={units} invoices={invoices} />}
            </Grid>
          </Grid>
          {loading && (
            <MDBox mb={3} textAlign="center">
              <CircularProgress />
            </MDBox>
          )}
        </MDBox>
        {/* {!loading && (
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                {chemicals.length > 0 && <SpraysCosts chemicals={chemicals} />}
              </Grid>
            </Grid>
          </MDBox>
        )}
        {loading && (
          <MDBox mb={3} textAlign="center">
            <CircularProgress />
          </MDBox>
        )} */}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Billing;
