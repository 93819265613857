// sig pad
import SignatureCanvas from "react-signature-canvas";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Icon from "@mui/material/Icon";
// @mui icons
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SprayInfoCard from "examples/Cards/InfoCards/EditWeedSprayInfoCard";
// import ProfilesList from "examples/Lists/ProfilesList";
import ChemicalsList from "examples/Lists/WeedSprayChemicalList";
import WeedSprayBlockList from "examples/Lists/WeedsprayBlockList";

// Overview page components
import Header from "layouts/weedsprays/weedspraydetail/components/Header";
import SprayerSettings from "layouts/weedsprays/weedspraydetail/components/SprayerSettings";
import WaterSettings from "layouts/weedsprays/weedspraydetail/components/WaterSettings";

// React
import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Firebase
import { collection, where, query, onSnapshot, setDoc, doc } from "firebase/firestore";
import { db } from "firebaseDb";

function Overview() {
  const { sprayId } = useParams();
  const [sprayInfo, setSprayInfo] = useState({});
  const [signature, setSignature] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const [hectares, setHectares] = useState(0);

  const handleOpen = () => setopenModal(true);
  const handleClose = () => setopenModal(false);
  const navigate = useNavigate();
  const handlePrint = () => {
    navigate(`/weedsprays/${sprayId}/pdf`);
  };

  const sigCanvas = useRef();
  const windowWidth =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const modalstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    textAlign: "center",
    p: 4,
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  const saveSignature = () => {
    setLoading(true);
    const trimmedDataURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    const docRef = doc(db, "Sprays", sprayInfo.key);
    const timestamp = new Date();
    setDoc(
      docRef,
      { signature: trimmedDataURL, signatureDate: timestamp.toDateString() },
      { merge: true }
    ).then(() => {
      setLoading(false);
      setopenModal(false);
    });
    setSignature(trimmedDataURL);
  };

  // Get Spray Data

  useEffect(() => {
    const sprayRef = collection(db, "Weedsprays");
    const q = query(sprayRef, where("sprayInstructionId", "==", sprayId));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.forEach((docu) => {
        setSprayInfo({ ...docu.data(), key: docu.id });
        setSignature(docu.data().signature);
      });
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    // Calculate Hectares
    let sum = 0;
    /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
    for (let i = 0; i < sprayInfo?.orchards?.length; i++) {
      for (let j = 0; j < sprayInfo?.orchards[i]?.blocks?.length; j++) {
        for (let k = 0; k < sprayInfo?.orchards[i]?.blocks[j]?.varieties?.length; k++) {
          if (sprayInfo?.orchards[i]?.blocks[j]?.checked) {
            sum += Number(sprayInfo?.orchards[i]?.blocks[j]?.varieties[k]?.hectares);
          }
        }
      }
    }
    const hec = Math.round(sum * 100) / 100;
    setHectares(hec);
  }, [sprayInfo]);

  if (sprayInfo.orchards) {
    let displayDate = "";

    if (sprayInfo.sprayDate) {
      if (sprayInfo.sprayDate.seconds !== 32503633200) {
        displayDate = sprayInfo.sprayDate.toDate().toLocaleString(undefined, {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      }
    }
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox sx={modalstyle}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} xl={12}>
                <MDTypography id="modal-modal-title" variant="h6" component="h2">
                  Signature
                </MDTypography>
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <SignatureCanvas
                  ref={sigCanvas}
                  penColor="black"
                  backgroundColor="rgba(246, 247, 188)"
                  canvasProps={{
                    width: windowWidth / 2,
                    height: 200,
                    className: "sigCanvas",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <MDButton
                  color="success"
                  onClick={() => {
                    saveSignature();
                  }}
                >
                  {loading ? <CircularProgress /> : "Save Signature"}
                </MDButton>
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <MDButton color="warning" onClick={() => clearSignature()}>
                  Clear
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </Modal>
        <Header>
          <MDBox mt={5} mb={3}>
            <MDBox mb={3} mr={5} textAlign="right">
              <MDButton variant="contained" color="primary" onClick={handlePrint}>
                Print PDF
              </MDButton>
            </MDBox>
            <Grid container spacing={1}>
              <Grid item xs={12} md={4} xl={4} sx={{ display: "flex" }}>
                <SprayInfoCard
                  title="spray information"
                  info={{
                    date: displayDate,
                    area: sprayInfo.area,
                    hectarage: `${hectares} ha`,
                  }}
                  shadow={false}
                  setSprayInfo={setSprayInfo}
                  sprayInfo={sprayInfo}
                  date={displayDate}
                />
                <Divider orientation="vertical" sx={{ mx: 0 }} />
              </Grid>
              <Grid item xs={12} md={4} xl={4}>
                <SprayerSettings title="sprayer settings" sprayInfo={sprayInfo} />
              </Grid>
              <Grid item xs={12} md={4} xl={4}>
                <WaterSettings title="water settings" sprayInfo={sprayInfo} hectares={hectares} />
              </Grid>
              <MDBox
                mt={0.5}
                width="100%"
                bgColor="grey"
                borderRadius={10}
                ml={1}
                textAlign="center"
              >
                <MDTypography
                  variant="caption"
                  fontWeight="bold"
                  color="white"
                  textTransform="uppercase"
                  sx={{ margin: "7px", fontSize: "1rem" }}
                >
                  Notes:{sprayInfo.notes}
                </MDTypography>
              </MDBox>

              <Grid item xs={12} xl={12}>
                <ChemicalsList
                  title="Chemical Info"
                  sprayInfo={sprayInfo}
                  shadow={false}
                  hectares={sprayInfo.hectares}
                />
              </Grid>
              <Grid item xs={12} xl={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <MDTypography>Show Block Information</MDTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <WeedSprayBlockList
                      title="Block Info"
                      sprayInfo={sprayInfo}
                      shadow={false}
                      setSprayInfo={setSprayInfo}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12} xl={12}>
                <Box sx={{ textAlign: "center" }}>
                  <MDButton
                    variant="contained"
                    color="secondary"
                    startIcon={<DriveFileRenameOutlineIcon />}
                    onClick={() => {
                      handleOpen();
                    }}
                  >
                    {signature ? "Change Signature" : "Sign Spray Record"}
                  </MDButton>
                </Box>
              </Grid>
              <Grid item xs={12} xl={12}>
                <Box sx={{ textAlign: "center" }}>
                  {signature ? <img alt="signature" src={signature} /> : null}
                </Box>
                <Box sx={{ textAlign: "center" }}>
                  {signature ? `Date: ${sprayInfo.signatureDate}` : null}
                </Box>
              </Grid>
            </Grid>
          </MDBox>
        </Header>
        <Footer />
      </DashboardLayout>
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <Box mt={5} mb={3} textAlign="center">
          <CircularProgress color="success" />
        </Box>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
