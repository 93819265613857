// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// DatePicker components
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useState, useEffect } from "react";

// firebase functions
import { db } from "firebaseDb";
import { getDocs, query, collection, orderBy, where } from "firebase/firestore";

require("dayjs/locale/en-au");

// Setting Locale
dayjs.locale("en-au");

function EditSprayInfoCard({ title, info, shadow, setSprayInfo }) {
  const [orchards, setOrchards] = useState([]);
  const [operators, setOperators] = useState([]);
  const [stages, setStages] = useState([]);
  const [blocks, setBlocks] = useState([]);

  const labels = [];
  const values = [];

  // Get Orchard Data
  useEffect(() => {
    const sprayRef = collection(db, "Orchards");
    const q = query(sprayRef, orderBy("siteCode", "asc"));
    getDocs(q).then((querySnapshot) => {
      const tempsites = [];
      querySnapshot.forEach((docu) => {
        if (!tempsites.some((e) => e.siteCode === docu.data().siteCode)) {
          tempsites.push({
            key: docu.id,
            siteCode: docu.data().siteCode,
            siteName: docu.data().siteName,
          });
        }
      });

      setOrchards(tempsites);
    });
  }, []);

  useEffect(() => {
    const operatorRef = collection(db, "Operators");
    const q = query(operatorRef, orderBy("name", "asc"));
    getDocs(q).then((querySnapshot) => {
      const tempoperators = [];
      querySnapshot.forEach((docu) => {
        if (!docu.data().archive) {
          tempoperators.push({
            key: docu.id,
            name: docu.data().name,
          });
        }
      });
      setOperators(tempoperators);
    });
  }, []);

  useEffect(() => {
    const areaRef = collection(db, "GrowthStage");
    const q = query(areaRef, orderBy("growthStage", "asc"));
    getDocs(q).then((querySnapshot) => {
      const tempstages = [];
      querySnapshot.forEach((docu) => {
        tempstages.push({
          key: docu.id,
          growthStage: docu.data().growthStage,
        });
      });
      setStages(tempstages);
    });
  }, []);

  const handleSelectOperator = (list, x) => {
    if (!list.includes(x)) {
      setSprayInfo((prevState) => ({
        ...prevState,
        operatorWorker: [...prevState.operatorWorker, x],
      }));
    } else {
      setSprayInfo((prevState) => ({
        ...prevState,
        operatorWorker: [...prevState.operatorWorker.filter((worker) => worker !== x)],
      }));
    }
  };

  const handleSelectStage = (x) => {
    setSprayInfo((prevState) => ({
      ...prevState,
      growthStage: x,
    }));
  };

  const handleSelectOrchard = (x) => {
    const blocksRef = collection(db, "Orchards");
    const q = query(blocksRef, where("siteCode", "==", x.siteCode));
    getDocs(q).then((querySnapshot) => {
      const tempblocks = [];
      querySnapshot.forEach((docu) => {
        if (!tempblocks.some((e) => e.block.code === docu.data().blockCode)) {
          tempblocks.push({
            key: docu.id,
            block: {
              code: docu.data().blockCode,
              varieties: [
                {
                  key: docu.id,
                  variety: docu.data().varietyName,
                  checked: true,
                  hectares: docu.data().hectares,
                },
              ],
              checked: false,
            },
          });
        } else {
          const index = tempblocks.findIndex((i) => i.block.code === docu.data().blockCode);
          tempblocks[index].block.varieties.push({
            key: docu.id,
            variety: docu.data().varietyName,
            checked: true,
            hectares: docu.data().hectares,
          });
        }
      });
      const sortedBlocks = tempblocks.sort((a, b) =>
        a.block.code.localeCompare(b.block.code, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );

      // Calculate Hectarage
      let sum = 0;
      /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
      for (let i = 0; i < blocks.length; i++) {
        for (let j = 0; j < blocks[i].block.varieties.length; j++) {
          if (blocks[i].block.varieties[j].checked && blocks[i].block.checked) {
            sum += Number(blocks[i].block.varieties[j].hectares);
          }
        }
      }
      const hec = Math.round(sum * 100) / 100;

      setBlocks(sortedBlocks);
      setSprayInfo((prevState) => ({
        ...prevState,
        siteCode: x.siteCode,
        siteName: x.siteName,
        blockVarieties: sortedBlocks,
        hectares: hec,
      }));
    });
  };

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => {
    if (label === "date") {
      return (
        <MDBox key={label} display="flex" py={1} pr={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="SPRAY DATE"
              value={values[key].seconds ? new Date(values[key].seconds * 1000) : values[key]}
              onChange={(newDate) => {
                setSprayInfo((prevState) => ({
                  ...prevState,
                  sprayDate: newDate.$d,
                }));
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </MDBox>
      );
    }
    if (label === "hectarage") {
      return (
        <MDBox key={label} display="flex" py={1} pr={2}>
          <TextField value={values[key]} label={label.toUpperCase()} fullWidth disabled />
        </MDBox>
      );
    }
    if (label === "orchard") {
      return (
        <MDBox sx={{ width: "100%" }} key={label} display="flex" py={1} pr={2}>
          <TextField value={values[key]} label="" fullWidth disabled />
        </MDBox>
      );
    }
    if (label === "operators" && operators.length > 0) {
      return (
        <FormControl key={label} size="large" sx={{ width: "100%", marginBottom: "10px" }}>
          <InputLabel id="orchard" sx={{ width: "100%" }}>
            Operators
          </InputLabel>
          <Select
            sx={{ height: "50px" }}
            labelId="operator-label"
            id="operator-select"
            value={values[key]}
            label="Operators"
            multiple
          >
            {operators.map((op) => (
              <MenuItem
                key={op.key}
                value={op.name}
                onClick={() => {
                  handleSelectOperator(values[key], op.name);
                }}
              >
                {op.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
    if (label === "sitecode" && orchards.length > 0) {
      return (
        <FormControl key={label} size="large" sx={{ width: "100%" }}>
          <InputLabel id="orchard" sx={{ width: "100%" }}>
            Orchard
          </InputLabel>
          <Select
            sx={{ height: "50px" }}
            labelId="orchard-label"
            id="orchard-select"
            value={values[key]}
            label="Orchard"
          >
            {orchards.length > 0 ? (
              orchards.map((site) => (
                <MenuItem
                  key={site.key}
                  value={site.siteCode}
                  onClick={() => {
                    handleSelectOrchard(site);
                  }}
                >
                  {site.siteCode}
                </MenuItem>
              ))
            ) : (
              <CircularProgress color="success" />
            )}
          </Select>
        </FormControl>
      );
    }
    if (label === "target") {
      return null;
    }
    if (label === "growth" && stages.length > 0) {
      return (
        <FormControl key={label} size="large" sx={{ width: "100%", marginBottom: "10px" }}>
          <InputLabel id="orchard" sx={{ width: "100%" }}>
            Stage
          </InputLabel>
          <Select
            sx={{ height: "50px" }}
            labelId="stage-label"
            id="stages-select"
            value={values[key]}
            label="Stages"
          >
            {stages.length > 0 ? (
              stages.map((stage) => (
                <MenuItem
                  key={stage.key}
                  value={stage.growthStage}
                  onClick={() => {
                    handleSelectStage(stage.growthStage);
                  }}
                >
                  {stage.growthStage}
                </MenuItem>
              ))
            ) : (
              <CircularProgress color="success" />
            )}
          </Select>
        </FormControl>
      );
    }
    return (
      <MDBox key={label} display="flex" py={1} pr={2}>
        <TextField
          sx={{ width: "100%" }}
          value={values[key]}
          label={label.toUpperCase()}
          fullWidth
          onChange={(e) => {
            setSprayInfo((prevState) => ({
              ...prevState,
              [label]: e.target.value,
            }));
          }}
        />
      </MDBox>
    );
  });

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none", backgroundColor: "#b2d6bb" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
          sx={{ fontSize: "1rem" }}
        >
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox opacity={0.3}>
          <Divider sx={{ background: "black" }} />
        </MDBox>
        <MDBox>{renderItems}</MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props
EditSprayInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props
EditSprayInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  info: PropTypes.any.isRequired,
  shadow: PropTypes.bool,
  setSprayInfo: PropTypes.func.isRequired,
};

export default EditSprayInfoCard;
