// react-routers components
// import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";

import { TableContainer } from "@mui/material";
import MDButton from "components/MDButton";

function PurchaseList({ title, invoiceData, shadow, setInvoiceData, edit }) {
  const handleDeletePurchase = (index) => {
    setInvoiceData((prev) => {
      const newData = { ...prev };
      const newArray = [...prev.purchases]; // make a copy of the original array

      newArray.splice(index, 1);
      newData.purchases = newArray;
      return newData; // return the new array
    });
  };
  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={4} px={2}>
        <MDTypography variant="h4" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <TableContainer>
        <Table aria-label="simple table" exportButton>
          <TableRow component="th">
            <TableCell sx={{ fontWeight: "bold" }} align="left">
              Chemical
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              Amount
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              Cost
            </TableCell>
            {edit && <TableCell sx={{ fontWeight: "bold" }} align="center" />}
          </TableRow>
          <TableBody>
            {invoiceData.purchases.map((row, index) => (
              <TableRow
                hover
                key={row.key}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">{row.chemical}</TableCell>
                <TableCell align="center">{`${row.amount} ${row.unit}`}</TableCell>
                <TableCell align="center">{`$${row.cost}`}</TableCell>
                {edit && (
                  <TableCell align="center">
                    <MDButton
                      variant="gradient"
                      color="error"
                      onClick={() => {
                        handleDeletePurchase(index);
                      }}
                    >
                      <Icon>delete</Icon>
                      Delete
                    </MDButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

// Setting default props for the ProfilesList
PurchaseList.defaultProps = {
  shadow: true,
  edit: false,
};

// Typechecking props for the ProfilesList
PurchaseList.propTypes = {
  title: PropTypes.string.isRequired,
  shadow: PropTypes.bool,
  edit: PropTypes.bool,
  invoiceData: PropTypes.objectOf.isRequired,
  setInvoiceData: PropTypes.func.isRequired,
};

export default PurchaseList;
