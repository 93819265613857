// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, enableIndexedDbPersistence } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAJSBnA6tWNrTgJNAI2sAxz_l4-s5BGgMI",
  authDomain: "south-orchard.firebaseapp.com",
  projectId: "south-orchard",
  storageBucket: "south-orchard.appspot.com",
  messagingSenderId: "791536342037",
  appId: "1:791536342037:web:8f2a5bef7173be6cd52a1c",
  measurementId: "G-HDVF9PLVDE",
};

const qcfirebaseConfig = {
  apiKey: "AIzaSyB1BsY9Ab9dMV3LbPikFimqGHREEFsRybA",
  authDomain: "qc-reports-c8a46.firebaseapp.com",
  projectId: "qc-reports-c8a46",
  storageBucket: "qc-reports-c8a46.appspot.com",
  messagingSenderId: "676850962574",
  appId: "1:676850962574:web:21bd5969855a852aebb9df",
  measurementId: "G-VBXV5RSXWC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const qcapp = initializeApp(qcfirebaseConfig, "secondary");
export const db = getFirestore(app);
export const qcdb = getFirestore(qcapp);
// Enable offline persistence
enableIndexedDbPersistence(db)
  .then(() => {
    console.log("Offline Persistence Enabled");
  })
  .catch((err) => {
    // failed to enable persistence
    console.error(err);
  });
enableIndexedDbPersistence(qcdb)
  .then(() => {
    console.log("Offline Persistence Enabled for QC App");
  })
  .catch((err) => {
    // failed to enable persistence
    console.error(err);
  });
export const storage = getStorage(app);
export const auth = getAuth(app);
export const qcauth = getAuth(qcapp);
