import React from "react";
import { IconButton } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { auth } from "../../../firebaseDb";

function SignOut() {
  function handleSignOut() {
    auth
      .signOut()
      .then(() => {
        // eslint-disable-next-line no-alert
        localStorage.setItem("user", null);
      })
      .catch((error) => {
        // eslint-disable-next-line no-alert
        alert("Error signing out: ", error);
      });
  }

  return (
    <IconButton
      color="inherit"
      onClick={() => {
        handleSignOut();
      }}
    >
      <ExitToAppIcon />
    </IconButton>
  );
}

export default SignOut;
