// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function SprayerSettings({ title, sprayInfo, setSprayInfo }) {
  const handleSetSprayerName = (value, index) => {
    setSprayInfo((prevState) => {
      const sprayerCopy = [...prevState.sprayer];
      sprayerCopy[index] = {
        ...sprayerCopy[index],
        sprayer: value,
      };
      return { ...prevState, sprayer: sprayerCopy };
    });
  };
  const handleSetSprayerGear = (value, index) => {
    setSprayInfo((prevState) => {
      const sprayerCopy = [...prevState.sprayer];
      sprayerCopy[index].setting = {
        ...sprayerCopy[index].setting,
        gear: value,
      };
      return { ...prevState, sprayer: sprayerCopy };
    });
  };
  const handleSetSprayerSpeed = (value, index) => {
    setSprayInfo((prevState) => {
      const sprayerCopy = [...prevState.sprayer];
      sprayerCopy[index].setting = {
        ...sprayerCopy[index].setting,
        speed: value,
      };
      return { ...prevState, sprayer: sprayerCopy };
    });
  };

  const handleSetSprayerNozzle = (value, index) => {
    setSprayInfo((prevState) => {
      const sprayerCopy = [...prevState.sprayer];
      sprayerCopy[index].setting = {
        ...sprayerCopy[index].setting,
        nozzles: value,
      };
      return { ...prevState, sprayer: sprayerCopy };
    });
  };

  const handleSetSprayerPressure = (value, index) => {
    setSprayInfo((prevState) => {
      const sprayerCopy = [...prevState.sprayer];
      sprayerCopy[index].setting = {
        ...sprayerCopy[index].setting,
        pressure: value,
      };
      return { ...prevState, sprayer: sprayerCopy };
    });
  };

  const handleSetSprayerFan = (value, index) => {
    setSprayInfo((prevState) => {
      const sprayerCopy = [...prevState.sprayer];
      sprayerCopy[index].setting = {
        ...sprayerCopy[index].setting,
        fan: value,
      };
      return { ...prevState, sprayer: sprayerCopy };
    });
  };

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2}>
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
          sx={{ fontSize: "1.3rem", paddingBottom: "10px" }}
        >
          {title}
        </MDTypography>
        <MDBox opacity={0.3}>
          <Divider sx={{ background: "black" }} />
        </MDBox>
      </MDBox>
      <Grid container>
        {sprayInfo.sprayer.map((tractor, index) => (
          <MDBox pb={2} px={2} lineHeight={1.25}>
            <MDBox display="flex" alignItems="center" mb={2} ml={-1.5}>
              <MDBox width="80%" ml={0.5}>
                <TextField
                  value={tractor.sprayer}
                  label="Sprayer(s)"
                  sx={{ fontSize: "1rem" }}
                  onChange={(e) => {
                    handleSetSprayerName(e.target.value, index);
                  }}
                />
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={2} ml={-1.5}>
              <MDBox width="80%" ml={0.5}>
                <TextField
                  value={tractor.setting.gear}
                  label="Gear"
                  sx={{ fontSize: "1rem" }}
                  onChange={(e) => {
                    handleSetSprayerGear(e.target.value, index);
                  }}
                />
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={2} ml={-1.5}>
              <MDBox width="80%" ml={0.5}>
                <TextField
                  value={tractor.setting.speed}
                  label="Speed (km/h)"
                  sx={{ fontSize: "1rem" }}
                  onChange={(e) => {
                    handleSetSprayerSpeed(e.target.value, index);
                  }}
                />
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={2} ml={-1.5}>
              <MDBox width="80%" ml={0.5}>
                <TextField
                  value={tractor.setting.pressure}
                  label="Pressure (PSI)"
                  sx={{ fontSize: "1rem" }}
                  onChange={(e) => {
                    handleSetSprayerPressure(e.target.value, index);
                  }}
                />
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={2} ml={-1.5}>
              <MDBox width="80%" ml={0.5}>
                <TextField
                  value={tractor.setting.fan}
                  label="Fan Setting"
                  sx={{ fontSize: "1rem" }}
                  onChange={(e) => {
                    handleSetSprayerFan(e.target.value, index);
                  }}
                />
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={2} ml={-1.5}>
              <MDBox width="80%" ml={0.5}>
                <TextField
                  value={tractor.setting.nozzles}
                  label="Nozzle Setting"
                  sx={{ fontSize: "1rem" }}
                  onChange={(e) => {
                    handleSetSprayerNozzle(e.target.value, index);
                  }}
                />
              </MDBox>
            </MDBox>
          </MDBox>
        ))}
      </Grid>
    </Card>
  );
}

// Setting default props
SprayerSettings.defaultProps = {};

// Typechecking props
SprayerSettings.propTypes = {
  title: PropTypes.string.isRequired,
  sprayInfo: PropTypes.objectOf.isRequired,
  setSprayInfo: PropTypes.func.isRequired,
};

export default SprayerSettings;
