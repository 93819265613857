/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MaterialReactTable from "material-react-table";

import { useMemo, useState, useEffect } from "react";

const totalChemical = (chem, amount, measure, hectares, unit, waterRate, tankCapacity) => {
  if (chem.actualChemical) {
    return {
      text: `${chem.actualChemical} ${unit}`,
      number: chem.actualChemical,
    };
  }
  const tanksperHectare = waterRate / tankCapacity;
  if (measure === "Tank") {
    return {
      text: `${Math.round(100 * amount * tanksperHectare * hectares) / 100} ${unit}`,
      number: Math.round(100 * amount * tanksperHectare * hectares) / 100,
    };
  }
  if (measure === "Hectare") {
    return {
      text: `${Math.round(100 * Number(amount) * hectares) / 100} ${unit}`,
      number: Math.round(100 * Number(amount) * hectares) / 100,
    };
  }
  if (measure === "1000 L") {
    return {
      text: `${Math.round((100 * Number(amount) * waterRate * hectares) / 1000) / 100} ${unit}`,
      number: Math.round((100 * Number(amount) * waterRate * hectares) / 1000) / 100,
    };
  }
  return null;
};

const TimestamptoDate = (timestamp) => {
  const { seconds } = timestamp;
  const { nanoseconds } = timestamp;

  const milliseconds = seconds * 1000 + nanoseconds / 1e6;
  const date = new Date(milliseconds).toDateString();
  return date;
};

function InvoiceSprayList({ title, invoiceData, shadow, chemicals }) {
  const [rows, setRows] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const totalCost = invoiceData.reduce((accumulator, current) => {
    const sprayCost = current.sprays.reduce((sprayAccumulator, spray) => {
      const chemicalCost = spray.chemicals.reduce(
        (chemicalAccumulator, chem) =>
          chemicalAccumulator +
          Number(
            (
              totalChemical(
                chem,
                chem.sprayRate,
                chem.measure,
                spray.hectares,
                chem.unit,
                spray.waterRate,
                spray.tankCapacity
              ).number * chemicals.chemicals.filter((c) => c.key === chem.key)[0].cost
            ).toFixed(2)
          ),
        0
      );
      return sprayAccumulator + chemicalCost;
    }, 0);
    return accumulator + sprayCost;
  }, 0);

  useEffect(() => {
    const temprows = [];

    // Sorting the array by siteName
    invoiceData.sort((a, b) => {
      const siteNameA = a.siteName.toUpperCase(); // Convert to uppercase for case-insensitive sorting
      const siteNameB = b.siteName.toUpperCase();

      if (siteNameA < siteNameB) {
        return -1; // a should come before b
      }
      if (siteNameA > siteNameB) {
        return 1; // a should come after b
      }
      return 0; // a and b are equal in terms of sorting
    });

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < invoiceData.length; i++) {
      // eslint-disable-next-line no-plusplus
      for (let j = 0; j < invoiceData[i].sprays?.length; j++) {
        // eslint-disable-next-line no-plusplus
        for (let k = 0; k < invoiceData[i].sprays[j].chemicals?.length; k++) {
          const spray = invoiceData[i].sprays[j];
          const chem = invoiceData[i].sprays[j].chemicals[k];
          temprows.push({
            color: i % 2 === 0 ? "white" : "#d9f3fa",
            id: `${i}${j}${k}`,
            orchard: invoiceData[i].siteName,
            hectares: spray.hectares,
            date: TimestamptoDate(invoiceData[i].sprays[j].sprayDate),
            chemicalName: invoiceData[i].sprays[j].chemicals[k].chemicalName,
            totalused: totalChemical(
              chem,
              chem.sprayRate,
              chem.measure,
              spray.hectares,
              chem.unit,
              spray.waterRate,
              spray.tankCapacity
            ).text,
            cost: `$${chemicals.chemicals.filter((c) => c.key === chem.key)[0].cost} per Unit`,
            chemicalcost: Number(
              (
                totalChemical(
                  chem,
                  chem.sprayRate,
                  chem.measure,
                  spray.hectares,
                  chem.unit,
                  spray.waterRate,
                  spray.tankCapacity
                ).number * chemicals.chemicals.filter((c) => c.key === chem.key)[0].cost
              ).toFixed(2)
            ),
          });
        }
      }
    }
    setRows(temprows);
  }, [invoiceData]);
  const columns = useMemo(
    () => [
      {
        header: "Orchard",
        accessorKey: "orchard",
      },
      {
        accessorKey: "hectares",
        header: "Ha",
      },
      {
        accessorKey: "date",
        header: "Spray Date",
      },
      {
        accessorKey: "chemicalName",
        header: "Chemical",
      },
      {
        accessorKey: "totalused",
        header: "Total Used",
      },
      {
        accessorKey: "cost",
        header: "Cost",
      },
      {
        accessorKey: "chemicalcost",
        header: "Chem. Cost",
        aggregationFn: "sum",
        AggregatedCell: ({ cell, table }) => (
          <>
            Total Cost - {table.getColumn(cell.row.groupingColumnId ?? "").columnDef.header}:{" "}
            <MDBox sx={{ color: "info.main", display: "inline", fontWeight: "bold" }}>
              {cell.getValue()?.toLocaleString?.("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </MDBox>
          </>
        ),
        Cell: ({ cell }) => (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
        Footer: () => (
          <Stack>
            Invoice Total:
            <MDBox color="warning.main">
              {totalCost.toLocaleString?.("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </MDBox>
          </Stack>
        ),
      },
    ],
    []
  );

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={4} px={2}>
        <MDTypography variant="h4" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox pt={4} px={2}>
        <MaterialReactTable
          columns={columns}
          data={rows}
          enableGrouping
          enableStickyHeader
          enableStickyFooter
          enableSelectAll={false}
          enableColumnActions={false}
          enablePagination={false}
          initialState={{
            density: "compact",
            expanded: false,
            grouping: ["orchard", "date"],
            pagination: { pageIndex: 0, pageSize: 20 },
            sorting: [{ id: "orchard", desc: false }],
            tableLayout: "fixed",
          }}
          muiToolbarAlertBannerChipProps={{ color: "primary" }}
          muiTableContainerProps={{ sx: { maxHeight: 700 } }}
          muiTableHeadCellProps={{
            // no useTheme hook needed, just use the `sx` prop with the theme callback
            sx: () => ({
              backgroundColor: "lightblue",
            }),
          }}
          muiTableBodyRowProps={({ row }) => ({
            onClick: row.getToggleSelectedHandler(),
            hover: false,
            sx: () => ({
              backgroundColor: row.original.color,
              cursor: "pointer",
            }),
          })}
          onRowSelectionChange={setRowSelection}
          state={{ rowSelection }}
        />
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfilesList
InvoiceSprayList.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
InvoiceSprayList.propTypes = {
  title: PropTypes.string.isRequired,
  shadow: PropTypes.bool,
  invoiceData: PropTypes.objectOf.isRequired,
  chemicals: PropTypes.objectOf.isRequired,
};

export default InvoiceSprayList;
