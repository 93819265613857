// @mui material components
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { useNavigate } from "react-router-dom";

// React
import { useState } from "react";

// firebase functions
import { db } from "firebaseDb";
import { addDoc, collection } from "firebase/firestore";

function Employee() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [employeeInfo, setEmployeeInfo] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const saveEmployee = () => {
    setLoading(true);
    const empRef = collection(db, "Operators");
    addDoc(empRef, employeeInfo).then(() => {
      setLoading(false);
      navigate("/setup");
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox
        component="li"
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        bgColor={darkMode ? "transparent" : "grey-100"}
        borderRadius="lg"
        p={3}
        mb={1}
        mt={10}
      >
        {!loading && (
          <MDBox width="100%" display="flex" flexDirection="column">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              mb={2}
            >
              <MDTypography
                variant="caption"
                color="text"
                mr={2}
                sx={{
                  fontSize: "1rem",
                  verticalAlign: "center",
                  left: "30px",
                  position: "relative",
                }}
              >
                Employee Name:
              </MDTypography>
              <TextField
                sx={{ left: "30px", position: "relative" }}
                value={employeeInfo.name}
                onChange={(e) => {
                  setEmployeeInfo((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }));
                }}
              />
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              mb={2}
            >
              <MDTypography
                variant="caption"
                color="text"
                mr={2}
                sx={{
                  fontSize: "1rem",
                  verticalAlign: "center",
                  left: "30px",
                  position: "relative",
                }}
              >
                Growsafe:
              </MDTypography>
              <TextField
                sx={{ left: "30px", position: "relative" }}
                value={employeeInfo.growSafe}
                onChange={(e) => {
                  setEmployeeInfo((prevState) => ({
                    ...prevState,
                    growSafe: e.target.value,
                  }));
                }}
              />
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              mb={2}
            >
              <MDTypography
                variant="caption"
                color="text"
                mr={2}
                sx={{
                  fontSize: "1rem",
                  verticalAlign: "center",
                  left: "30px",
                  position: "relative",
                }}
              >
                CropSure ID:
              </MDTypography>
              <TextField
                sx={{ left: "30px", position: "relative" }}
                value={employeeInfo.cropSureID}
                onChange={(e) => {
                  setEmployeeInfo((prevState) => ({
                    ...prevState,
                    cropSureID: e.target.value,
                  }));
                }}
              />
            </MDBox>
          </MDBox>
        )}
        {loading && <CircularProgress />}
      </MDBox>
      <MDBox mb={1} lineHeight={0} textAlign="center">
        <MDButton
          variant="contained"
          color="success"
          disabled={loading}
          onClick={() => {
            saveEmployee();
          }}
        >
          <Icon>save</Icon>&nbsp;save
        </MDButton>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Employee;
