// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";

function Chemicals({ spray, setSpray, operators, stages }) {
  const handleSelectStage = (x) => {
    setSpray((prevState) => ({
      ...prevState,
      growthStage: x,
    }));
  };

  const handleSelectOperator = (x) => {
    if (!spray.operatorWorker.includes(x)) {
      setSpray((prevState) => ({
        ...prevState,
        operatorWorker: [...prevState.operatorWorker, x],
      }));
    } else {
      setSpray((prevState) => ({
        ...prevState,
        operatorWorker: [...prevState.operatorWorker.filter((worker) => worker !== x)],
      }));
    }
  };

  return (
    <MDBox>
      <Grid container spacing={2} sx={{ padding: 3 }}>
        <Grid item xs={12} md={6} lg={4}>
          <FormControl size="large" sx={{ minWidth: "100%" }}>
            <InputLabel id="target-area">
              <Icon
                sx={{
                  fontWeight: "bold",
                  color: ({ palette: { success } }) => success.main,
                  paddingRight: 2,
                }}
              >
                park
              </Icon>
              Stage
            </InputLabel>
            <Select
              sx={{ height: "50px" }}
              labelId="operator-label"
              id="operator-select"
              value={spray.growthStage}
              label="Stage____"
            >
              {stages.length > 0 ? (
                stages.map((st) => (
                  <MenuItem
                    key={st.key}
                    value={st.growthStage}
                    onClick={() => {
                      handleSelectStage(st.growthStage);
                    }}
                  >
                    {st.growthStage}
                  </MenuItem>
                ))
              ) : (
                <CircularProgress color="success" />
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <FormControl size="large" sx={{ width: "100%" }}>
            <InputLabel id="target-area">
              <Icon
                sx={{
                  fontWeight: "bold",
                  color: ({ palette: { success } }) => success.main,
                  paddingRight: 2,
                }}
              >
                person
              </Icon>
              Operator(s)
            </InputLabel>
            <Select
              sx={{ height: "50px" }}
              multiple
              labelId="operator-label"
              id="operator-select"
              value={spray.operatorWorker}
              label="Operatorss__"
            >
              {operators.length > 0 ? (
                operators.map((op) => (
                  <MenuItem
                    key={op.key}
                    value={op.name}
                    onClick={() => {
                      handleSelectOperator(op.name);
                    }}
                  >
                    {op.name}
                  </MenuItem>
                ))
              ) : (
                <CircularProgress color="success" />
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <TextField
            sx={{
              width: "100%",
              "& .MuiInputBase-root": {
                height: 50,
              },
            }}
            value={spray.notes}
            label="Notes"
            onChange={(e) => {
              setSpray((prevState) => ({
                ...prevState,
                notes: e.target.value,
              }));
            }}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
}

// Typechecking props
Chemicals.propTypes = {
  operators: PropTypes.objectOf(
    PropTypes.oneOf([PropTypes.string, PropTypes.array, PropTypes.object])
  ).isRequired,
  spray: PropTypes.objectOf(PropTypes.oneOf([PropTypes.string, PropTypes.array, PropTypes.object]))
    .isRequired,
  stages: PropTypes.objectOf(PropTypes.oneOf([PropTypes.string, PropTypes.array, PropTypes.object]))
    .isRequired,
  setSpray: PropTypes.func.isRequired,
};

export default Chemicals;
