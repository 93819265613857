// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Icon from "@mui/material/Icon";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// React
import { useEffect, useState } from "react";
import { useAuth } from "layouts/authentication/AuthContext";
// Data
import spraysTableData from "layouts/sprays/data/spraysTableData";
// Firebase
import { db } from "firebaseDb";
import {
  getDocs,
  collection,
  query,
  where,
  setDoc,
  doc,
  getDoc,
  // updateDoc,
} from "firebase/firestore";

// import { saveAs } from "file-saver";

function Tables() {
  const [openDialog, setOpenDialog] = useState(false);
  const [openPublishDialog, setOpenPublishDialog] = useState(false);
  const [openUnpublishDialog, setOpenUnpublishDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sprayID, setID] = useState("");
  const [sprayInfo, setSprayInfo] = useState("");
  const [status, setStatus] = useState("Ready to Spray");

  const { user } = useAuth();
  const authToken = user.accessToken;

  const handleOpenDialog = (id) => {
    setOpenDialog(true);
    setID(id);
  };

  const handleOpenPublishDialog = (spray) => {
    setOpenPublishDialog(true);
    setID(spray.sprayInstructionId);
    setSprayInfo(spray);
  };

  const handleOpenUnpublishDialog = (id) => {
    setOpenUnpublishDialog(true);
    setID(id);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClosePublishDialog = () => {
    setOpenPublishDialog(false);
  };

  const handleCloseUnpublishDialog = () => {
    setOpenUnpublishDialog(false);
  };

  // Delete Spray
  const deleteSpray = () => {
    setLoading(true);
    const sprayRef = collection(db, "Sprays");
    const q = query(sprayRef, where("sprayInstructionId", "==", sprayID));
    getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((docu) => {
        setDoc(doc(db, "Sprays", docu.id), { archive: true }, { merge: true }).then(() => {
          handleCloseDialog();
          setLoading(false);
        });
      });
    });
  };

  // Publish Spray
  const publishSpray = (siteData, chems, sprayers, operators) => {
    const myHeaders = new Headers({
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
      "x-api-key": "santaisrealbutyoumightnotbe",
    });

    const currentDate = new Date();
    const nzDate = new Date(currentDate);
    nzDate.setHours(currentDate.getHours() + 13);
    const milliseconds = sprayInfo.sprayDate.seconds * 1000;
    const utcSprayDate = new Date(milliseconds);
    utcSprayDate.setHours(utcSprayDate.getHours() + 13);
    const formattedSprayDate = utcSprayDate.toISOString();
    const formattedDate = nzDate.toISOString();
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        date: formattedDate,
        sprayDate: formattedSprayDate,
        waterRate: sprayInfo.waterRate,
        applicatorID: operators,
        sprayerID: sprayers,
        site: siteData,
        chemicals: chems,
      }),
      redirect: "follow",
    };
    fetch("http://localhost:3002/publish", requestOptions)
      .then((response) => {
        if (response.status === 401) {
          alert("Unauthorized");
          setLoading(false);
          handleClosePublishDialog();
          throw new Error("Unauthorized");
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);
        handleClosePublishDialog();
        setLoading(false);
        const sprayRef = collection(db, "Sprays");
        const q = query(sprayRef, where("sprayInstructionId", "==", sprayID));
        getDocs(q).then((querySnapshot) => {
          querySnapshot.forEach((docu) => {
            setDoc(doc(db, "Sprays", docu.id), { status: "Published" }, { merge: true }).then(
              () => {
                handleClosePublishDialog();
                setLoading(false);
              }
            );
          });
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.message !== "Unauthorized") {
          alert("Error with API server", error);
          setLoading(false);
          handleClosePublishDialog();
        }
      });
  };

  const fetchCropSureIDs = async () => {
    setLoading(true);
    const siteData = [];
    const sprayerData = [];

    const operatorsPromises = sprayInfo.operatorWorker.map((worker) => {
      const q = query(collection(db, "Operators"), where("name", "==", worker));
      return getDocs(q).then((querySnapshot) => {
        const operators = [];
        querySnapshot.forEach((docu) => {
          operators.push(docu.data().cropSureID);
        });
        return operators;
      });
    });

    const sprayersPromises = sprayInfo.sprayer.map((sprayer) => {
      const sprayerRef = doc(db, "Tractors", sprayer.key);
      return getDoc(sprayerRef).then((docu) => {
        const { cropSureID } = docu.data();
        sprayerData.push(cropSureID);
        return cropSureID;
      });
    });

    const blocksPromises = sprayInfo.blockVarieties.reduce(
      (promises, block) =>
        promises.concat(
          block.block.varieties
            .filter((variety) => block.block.checked && variety.checked)
            .map((variety) => {
              const blockRef = doc(db, "Orchards", variety.key);
              return getDoc(blockRef).then((docu) => {
                const siteID = docu.data().cropSureID;
                const blockID = docu.data().cropSureBlockID;
                const varietyID = docu.data().cropSureBlockVarietyID;

                const siteIndex = siteData.findIndex((site) => site.siteID === siteID);
                if (siteIndex === -1) {
                  // Add new site entry if not found
                  siteData.push({ siteID, blockIDs: [{ blockID, varietyIDs: [varietyID] }] });
                } else {
                  // Add block and variety to existing site entry
                  const blockIndex = siteData[siteIndex].blockIDs.findIndex(
                    (bl) => bl.blockID === blockID
                  );
                  if (blockIndex === -1) {
                    // Add new block entry if not found
                    siteData[siteIndex].blockIDs.push({ blockID, varietyIDs: [varietyID] });
                  } else {
                    // Add variety to existing block entry
                    siteData[siteIndex].blockIDs[blockIndex].varietyIDs.push(varietyID);
                  }
                }

                return { siteID, blockID, varietyID };
              });
            })
        ),
      []
    );

    const codes = [
      {
        value: "93896",
        label: "g/Ha",
        unit: "Gram",
      },
      {
        value: "93898",
        label: "Kg/Ha",
        unit: "Kilogram",
      },
      {
        value: "93900",
        label: "L/Ha",
        unit: "Litre",
      },
      {
        value: "93902",
        label: "ml/Ha",
        unit: "Millilitre",
      },
    ];

    const chemsPromises = sprayInfo.chemicals.map((chem) => {
      if (chem.measure !== "Hectare") {
        alert("Measure is not Hectares! Bad things will happen");
      }
      const chemRef = doc(db, "Chemicals", chem.key);
      return getDoc(chemRef).then((docu) => {
        const { cropSureID } = docu.data();
        return {
          chemID: cropSureID,
          chemRate: chem.sprayRate,
          chemUnit: codes.find((code) => code.unit === chem.unit).value,
        };
      });
    });

    try {
      const operatorsArrays = await Promise.all(operatorsPromises);
      const operators = operatorsArrays.flat(); // Flatten the array of arrays
      const blocks = await Promise.all(blocksPromises);
      const sprayers = await Promise.all(sprayersPromises);
      const chems = await Promise.all(chemsPromises);

      // Check if any of the IDs are undefined
      blocks.forEach(({ siteID, blockID, varietyID }) => {
        if (!siteID || !blockID || !varietyID) {
          alert("One of the IDs (siteID, blockID, varietyID) is undefined");
          throw new Error("One of the IDs (siteID, blockID, varietyID) is undefined");
        }
      });

      publishSpray(siteData, chems, sprayers, operators);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Unpublish Spray
  const unpublishSpray = () => {
    setLoading(true);
    const sprayRef = collection(db, "Sprays");
    const q = query(sprayRef, where("sprayInstructionId", "==", sprayID));
    getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((docu) => {
        setDoc(doc(db, "Sprays", docu.id), { status: "Ready to Spray" }, { merge: true }).then(
          () => {
            handleCloseUnpublishDialog();
            setLoading(false);
          }
        );
      });
    });
  };

  // const updateSprays = () => {
  //   setLoading(true);
  //   const sprayRef = collection(db, "Sprays");

  //   // Create a valid date object for comparison
  //   const comparisonDate = new Date("2023-06-01");

  //   getDocs(sprayRef).then((querySnapshot) => {
  //     querySnapshot.forEach((docu) => {
  //       if (new Date(docu.data().sprayDate.seconds * 1000) > comparisonDate) {
  //         console.log(docu.id);
  //         setDoc(doc(db, "Sprays", docu.id), { status: "Ready to Spray" }, { merge: true }).then(
  //           () => {
  //             console.log("finished");
  //             handleCloseUnpublishDialog();
  //             setLoading(false);
  //           }
  //         );
  //       }
  //     });
  //   });
  //   setLoading(false);
  //   handleCloseUnpublishDialog();
  // };

  const { columns, rows, clickEvent } = spraysTableData(
    handleOpenDialog,
    handleOpenPublishDialog,
    handleOpenUnpublishDialog,
    status
  );
  const [rowInfo, setRowInfo] = useState(
    rows
      .filter((row) => row.status === "Ready to Spray" && row.date === "01/01/3000")
      .sort((a, b) => b.sprayID - a.sprayID)
  );

  const sortObjectsByDate = (objectArray) => {
    // Custom sorting function
    const customSort = (a, b) => {
      const dateA = new Date(`${a.date.split("/").reverse().join("-")}T00:00:00Z`).getTime();
      const dateB = new Date(`${b.date.split("/").reverse().join("-")}T00:00:00Z`).getTime();
      return dateB - dateA;
    };

    // Sort the array of objects using the custom sorting function
    objectArray.sort(customSort);

    return objectArray;
  };

  // async function updateSpraysCollection() {
  //   try {
  //     const spraysCollection = collection(db, "Sprays");

  //     // Query for sprays before the specified date
  //     const q = query(spraysCollection, where("sprayDate", "==", ""));
  //     const querySnapshot = await getDocs(q);

  //     // Iterate over each document and update it
  //     querySnapshot.forEach(async (document) => {
  //       console.log(document.id);
  //       const docRef = doc(db, "Sprays", document.id);
  //       await updateDoc(docRef, { sprayDate: new Date(3000, 0, 1) });
  //     });
  //   } catch (error) {
  //     console.error("Error updating documents: ", error);
  //   } finally {
  //     console.log("Documents updated successfully");
  //   }

  //   // try {
  //   //   const spraysCollection = collection(db, "Sprays");
  //   //   const dateSearch = new Date("2023-05-12T12:00:00.000Z");

  //   //   // Query for sprays before the specified date
  //   //   const q = query(spraysCollection, where("sprayDate", "<=", dateSearch));
  //   //   const querySnapshot = await getDocs(q);

  //   //   // Iterate over each document and update it
  //   //   querySnapshot.forEach(async (document) => {
  //   //     console.log(document.id);
  //   //     const docRef = doc(db, "Sprays", document.id);
  //   //     await updateDoc(docRef, { historicalArchive: true });
  //   //   });
  //   // } catch (error) {
  //   //   console.error("Error updating documents: ", error);
  //   // } finally {
  //   //   console.log("Documents updated successfully");
  //   // }
  // }

  useEffect(() => {
    if (status === "Ready to Spray") {
      setRowInfo(() =>
        rows
          .filter((row) => row.status === status && row.date === "01/01/3000" && !row.weedspray)
          .sort((a, b) => b.sprayID - a.sprayID)
      );
    }
    if (status === "Sprayed") {
      setRowInfo(() =>
        sortObjectsByDate(
          rows.filter(
            (row) => row.status === "Ready to Spray" && row.date !== "01/01/3000" && !row.weedspray
          )
        )
      );
    }
    if (status === "Published") {
      setRowInfo(() =>
        sortObjectsByDate(rows.filter((row) => row.status === "Published" && !row.weedspray))
      );
    }
  }, [status, rows]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog
        open={openUnpublishDialog}
        onClose={handleCloseUnpublishDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Unpublish Record?</DialogTitle>
        <DialogContent>
          {loading && (
            <MDBox justifyContent="center" display="flex">
              <CircularProgress />
            </MDBox>
          )}
          {!loading && (
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to unpublish this spray record?
            </DialogContentText>
          )}
        </DialogContent>

        {!loading && (
          <DialogActions>
            <MDButton onClick={handleCloseUnpublishDialog}>No</MDButton>
            <MDButton
              onClick={() => {
                unpublishSpray();
              }}
              autoFocus
            >
              Yes
            </MDButton>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Record?</DialogTitle>
        <DialogContent>
          {loading && (
            <MDBox justifyContent="center" display="flex">
              <CircularProgress />
            </MDBox>
          )}
          {!loading && (
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this spray record?
            </DialogContentText>
          )}
        </DialogContent>

        {!loading && (
          <DialogActions>
            <MDButton onClick={handleCloseDialog}>No</MDButton>
            <MDButton
              onClick={() => {
                deleteSpray();
              }}
              autoFocus
            >
              Yes
            </MDButton>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        open={openPublishDialog}
        onClose={handleClosePublishDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Publish Record?</DialogTitle>
        <DialogContent>
          {loading && (
            <MDBox justifyContent="center" display="flex">
              <CircularProgress />
            </MDBox>
          )}
          {!loading && (
            <DialogContentText id="alert-dialog-description">
              Publish this spray to Cropsure?
            </DialogContentText>
          )}
        </DialogContent>
        {!loading && (
          <DialogActions>
            <MDButton onClick={handleClosePublishDialog}>No</MDButton>
            <MDButton
              onClick={() => {
                fetchCropSureIDs();
              }}
              autoFocus
            >
              Yes
            </MDButton>
          </DialogActions>
        )}
      </Dialog>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Sprays Table
                </MDTypography>
                <MDBox justifyContent="center" display="flex">
                  <MDButton
                    size="medium"
                    sx={{ marginLeft: "10px" }}
                    color={status === "Ready to Spray" ? "primary" : "secondary"}
                    onClick={() => {
                      setStatus("Ready to Spray");
                    }}
                  >
                    Ready to Spray
                    <Icon>checklist</Icon>
                  </MDButton>
                  <MDButton
                    size="medium"
                    sx={{ marginLeft: "10px" }}
                    color={status === "Sprayed" ? "primary" : "secondary"}
                    onClick={() => {
                      setStatus("Sprayed");
                    }}
                  >
                    Sprayed
                    <Icon>waterdrop</Icon>
                  </MDButton>
                  <MDButton
                    size="medium"
                    sx={{ marginLeft: "10px" }}
                    color={status === "Published" ? "primary" : "secondary"}
                    onClick={() => {
                      setStatus("Published");
                    }}
                  >
                    Published
                    <Icon>backup</Icon>
                  </MDButton>
                  {/* <MDButton
                    size="medium"
                    sx={{ marginLeft: "10px" }}
                    color="white"
                    onClick={() => {
                      updateSpraysCollection();
                    }}
                  >
                    Update Sprays
                    <Icon>checklist</Icon>
                  </MDButton> */}
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                {rowInfo.length > 0 && (
                  <DataTable
                    table={{ columns, rows: rowInfo, clickEvent }}
                    entriesPerPage
                    showTotalEntries
                    canSearch
                    isSorted={false}
                  />
                )}
                {rowInfo.length === 0 && (
                  <MDBox justifyContent="center" display="flex" marginBottom="20px">
                    <CircularProgress />
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
