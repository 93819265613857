import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import RunTable from "examples/Tables/RunTable";

function RunData({ info, setRow, selectedrow }) {
  const columns = [
    { Header: "date", accessor: "date", width: "25%", align: "left" },
    { Header: "orchard", accessor: "orchard", width: "25%", align: "left" },
    { Header: "variety", accessor: "variety", align: "center" },
    { Header: "export %", accessor: "completion", align: "center" },
  ];

  const rows = info;

  const [menu, setMenu] = useState(null);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Placeholder</MenuItem>
    </Menu>
  );

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Packing Runs
          </MDTypography>
        </MDBox>
        <MDBox color="text" px={2}>
          <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
            more_vert
          </Icon>
        </MDBox>
        {renderMenu}
      </MDBox>
      <MDBox>
        <RunTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted
          noEndBorder
          entriesPerPage={false}
          canSearch
          setRow={setRow}
          selectedrow={selectedrow}
        />
      </MDBox>
    </Card>
  );
}

// Typechecking props for the PieChart
RunData.propTypes = {
  info: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  setRow: PropTypes.func.isRequired,
  selectedrow: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default RunData;
