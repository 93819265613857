// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";

import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Orchard page components
import Orchard from "layouts/setup/components/Orchard";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

import { useNavigate } from "react-router-dom";

function Orchards({ orchards, varieties }) {
  const [itemsToShow, setItemsToShow] = useState(3);
  const [searchQuery, setSearchQuery] = useState("");

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const navigate = useNavigate();

  const handleLoadMore = () => {
    setItemsToShow((prevState) => prevState + 10);
  };

  const filteredOrchards = orchards.filter((orchard) =>
    (orchard.siteCode + orchard.siteName).toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <Card id="delete-account">
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Orchard Information
        </MDTypography>
        <TextField
          id="search"
          label="Search Orchards"
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
        />
      </MDBox>
      <MDButton
        variant="text"
        color={darkMode ? "white" : "dark"}
        onClick={() => {
          navigate(`/setup/addorchard/`, {
            state: { varieties },
          });
        }}
      >
        <Icon>add</Icon>&nbsp;Add Orchard
      </MDButton>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0} pb={8}>
          {filteredOrchards.slice(0, itemsToShow).map((orc) => (
            <Orchard key={orc.key} site={orc} varieties={varieties} />
          ))}
        </MDBox>
        {filteredOrchards.length > itemsToShow && (
          <MDBox display="flex" justifyContent="center" alignItems="center" p={3}>
            Load more...
            <Icon
              sx={{ cursor: "pointer", fontWeight: "bold" }}
              fontSize="large"
              onClick={() => {
                handleLoadMore();
              }}
            >
              keyboard_arrow_down
            </Icon>
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
}
// Typechecking props
Orchards.propTypes = {
  orchards: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};
export default Orchards;
