/* eslint-disable react/forbid-prop-types */
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Timeline context
import { useTimeline } from "examples/Timeline/context";

// Custom styles for the TimelineItem
import timelineItem from "examples/Timeline/TimelineItem/styles";

function BlockTimeline({
  color,
  icon,
  title,
  description,
  lastItem,
  blockInfo,
  currentBlock,
  setCurrentBlock,
}) {
  const isDark = useTimeline();

  const handleSelectBlock = (x) => {
    setCurrentBlock(x);
  };

  return (
    <MDBox position="relative" mb={3} sx={(theme) => timelineItem(theme, { lastItem, isDark })}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor={color}
        color="white"
        width="2rem"
        height="2rem"
        borderRadius="50%"
        position="absolute"
        top="8%"
        left="2px"
        zIndex={2}
        sx={{ fontSize: ({ typography: { size } }) => size.sm }}
      >
        <Icon fontSize="inherit">{icon}</Icon>
      </MDBox>
      <MDBox ml={5.75} pt={description ? 0.7 : 0.5} lineHeight={0} maxWidth="30rem">
        <MDTypography variant="button" fontWeight="medium" color={isDark ? "white" : "dark"}>
          {title}
        </MDTypography>
        <MDBox mt={0.5}>
          <FormControl size="large" sx={{ width: "100%" }}>
            <InputLabel id="orchard" sx={{ width: "100%", marginTop: "10px" }}>
              Select a Block
            </InputLabel>
            <Select
              sx={{ height: "50px", marginTop: "10px" }}
              value={currentBlock.blockName}
              label="Select an Block"
            >
              {blockInfo.length > 0 ? (
                blockInfo.map((block) => (
                  <MenuItem
                    key={block.key}
                    value={block.blockName}
                    onClick={() => {
                      handleSelectBlock(block);
                    }}
                  >
                    {block.blockName}
                  </MenuItem>
                ))
              ) : (
                <CircularProgress color="success" />
              )}
            </Select>
          </FormControl>
        </MDBox>
        <MDBox mt={2} mb={1.5}>
          {description ? (
            <MDTypography variant="button" color={isDark ? "white" : "dark"}>
              {description}
            </MDTypography>
          ) : null}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of TimelineItem
BlockTimeline.defaultProps = {
  color: "info",
  lastItem: false,
  description: "",
};

// Typechecking props for the TimelineItem
BlockTimeline.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  blockInfo: PropTypes.object.isRequired,
  description: PropTypes.string,
  lastItem: PropTypes.bool,
};

export default BlockTimeline;
