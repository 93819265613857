// @mui material components
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { useNavigate } from "react-router-dom";

// React
import { useState } from "react";

// firebase functions
import { db } from "firebaseDb";
import { addDoc, collection } from "firebase/firestore";
// import { getDocs, query, collection, orderBy, setDoc, doc } from "firebase/firestore";

function SprayerAdd() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [sprayerinfo, setSprayerInfo] = useState({
    sprayerName: "",
    sprayerCode: "",
    calibrationIssued: "",
    nozzles: "",
    tankCapacity: "",
    weedsprayer: false,
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // Handler to toggle the checkbox
  const handleCheckboxChange = (event) => {
    setSprayerInfo((prevState) => ({
      ...prevState,
      weedsprayer: event.target.checked,
    }));
  };

  const saveSprayer = () => {
    setLoading(true);
    const sprayerRef = collection(db, "Tractors");
    addDoc(sprayerRef, sprayerinfo).then(() => {
      setLoading(false);
      navigate("/setup");
    });
  };

  const setSettingProperty = (index, property, value) => {
    setSprayerInfo((prevState) => {
      const prevArray = prevState.settings;
      prevArray[index][property] = value;
      return { ...prevState, settings: prevArray };
    });
  };

  const addSetting = () => {
    setSprayerInfo((prevState) => {
      let prevArray = [];
      if (prevState.settings) {
        prevArray = prevState.settings;
        prevArray.push({
          settingName: "",
          fan: "",
          gear: "",
          nozzles: "",
          pressure: "",
          speed: "",
          sprayRate: "",
        });
      } else {
        prevArray.push({
          settingName: "",
          fan: "",
          gear: "",
          nozzles: "",
          pressure: "",
          speed: "",
          sprayRate: "",
        });
      }

      return { ...prevState, settings: prevArray };
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox
        component="li"
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        bgColor={darkMode ? "transparent" : "grey-100"}
        borderRadius="lg"
        p={3}
        mb={1}
        mt={10}
      >
        {!loading && (
          <MDBox width="100%" display="flex" flexDirection="column">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              mb={2}
            >
              <MDTypography
                variant="caption"
                color="text"
                mr={2}
                sx={{
                  fontSize: "1rem",
                  verticalAlign: "center",
                  left: "30px",
                  position: "relative",
                }}
              >
                Sprayer Name:
              </MDTypography>
              <TextField
                sx={{ left: "30px", position: "relative" }}
                value={sprayerinfo.sprayerName}
                onChange={(e) => {
                  setSprayerInfo((prevState) => ({
                    ...prevState,
                    sprayerName: e.target.value,
                  }));
                }}
              />
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              mb={2}
            >
              <MDTypography
                variant="caption"
                color="text"
                sx={{
                  fontSize: "1rem",
                  verticalAlign: "center",
                  left: "50px",
                  position: "relative",
                }}
              >
                Weedsprayer
              </MDTypography>
              <Checkbox
                checked={sprayerinfo.weedsprayer}
                sx={{
                  fontSize: "1rem",
                  verticalAlign: "center",
                  left: "50px",
                  position: "relative",
                }}
                onChange={handleCheckboxChange}
              />
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              mb={2}
            >
              <MDTypography
                variant="caption"
                color="text"
                mr={2}
                sx={{
                  fontSize: "1rem",
                  verticalAlign: "center",
                  left: "30px",
                  position: "relative",
                }}
              >
                Sprayer Code:
              </MDTypography>
              <TextField
                sx={{ left: "30px", position: "relative" }}
                value={sprayerinfo.sprayerCode}
                onChange={(e) => {
                  setSprayerInfo((prevState) => ({
                    ...prevState,
                    sprayerCode: e.target.value,
                  }));
                }}
              />
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              mb={2}
            >
              <MDTypography
                variant="caption"
                color="text"
                mr={2}
                sx={{
                  fontSize: "1rem",
                  verticalAlign: "center",
                  left: "30px",
                  position: "relative",
                }}
              >
                Tank Capacity(L):
              </MDTypography>
              <TextField
                type="number"
                sx={{ left: "30px", position: "relative" }}
                value={sprayerinfo.tankCapacity}
                onChange={(e) => {
                  setSprayerInfo((prevState) => ({
                    ...prevState,
                    tankCapacity: e.target.value,
                  }));
                }}
              />
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              mb={2}
            >
              <MDTypography
                variant="caption"
                color="text"
                mr={2}
                sx={{
                  fontSize: "1rem",
                  verticalAlign: "center",
                  left: "30px",
                  position: "relative",
                }}
              >
                Calibration Date:
              </MDTypography>
              <TextField
                type="date"
                sx={{ left: "30px", position: "relative" }}
                value={sprayerinfo.calibrationIssued}
                onChange={(e) => {
                  setSprayerInfo((prevState) => ({
                    ...prevState,
                    calibrationIssued: e.target.value,
                  }));
                }}
              />
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              mb={2}
            >
              <MDButton
                variant="contained"
                color="secondary"
                disabled={loading}
                onClick={() => {
                  addSetting();
                }}
              >
                <Icon>add</Icon>&nbsp;add sprayer setting
              </MDButton>
            </MDBox>
            <Card
              sx={{
                marginTop: "20px",
                width: "100%",
                marginBottom: "20px",
              }}
            >
              <Table>
                {sprayerinfo.settings?.length > 0 &&
                  sprayerinfo.settings.map((s, index) => (
                    <TableRow>
                      <TableCell>
                        <TextField
                          value={`${s.settingName}`}
                          label="Setting Name"
                          onChange={(e) => setSettingProperty(index, "settingName", e.target.value)}
                        />
                      </TableCell>
                      <TableCell>
                        <MDBox>
                          <TextField
                            value={`${s.sprayRate}`}
                            label="Spray Rate"
                            onChange={(e) => setSettingProperty(index, "sprayRate", e.target.value)}
                          />
                        </MDBox>
                      </TableCell>
                      <TableCell>
                        <MDBox>
                          <TextField
                            value={`${s.gear}`}
                            label="Gear"
                            onChange={(e) => setSettingProperty(index, "gear", e.target.value)}
                          />
                        </MDBox>
                      </TableCell>
                      <TableCell>
                        <MDBox>
                          <TextField
                            value={`${s.speed}`}
                            label="Speed(km/h)"
                            onChange={(e) => setSettingProperty(index, "speed", e.target.value)}
                          />
                        </MDBox>
                      </TableCell>
                      <TableCell>
                        <MDBox>
                          <TextField
                            value={`${s.pressure}`}
                            label="Pressure(PSI)"
                            onChange={(e) => setSettingProperty(index, "pressure", e.target.value)}
                          />
                        </MDBox>
                      </TableCell>
                      <TableCell>
                        <FormControl size="large" sx={{ minWidth: "150px" }}>
                          <InputLabel>
                            <Icon
                              sx={{
                                fontWeight: "bold",
                                color: ({ palette: { success } }) => success.main,
                                paddingRight: 2,
                              }}
                            >
                              air
                            </Icon>
                            Fan
                          </InputLabel>

                          <Select sx={{ height: "45px" }} value={s.fan} label="Fan____ ">
                            <MenuItem
                              value="High"
                              onClick={() => {
                                setSettingProperty(index, "fan", "High");
                              }}
                            >
                              High
                            </MenuItem>
                            <MenuItem
                              value="Low"
                              onClick={() => {
                                setSettingProperty(index, "fan", "Low");
                              }}
                            >
                              Low
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  ))}
              </Table>
            </Card>
          </MDBox>
        )}
        {loading && <CircularProgress />}
      </MDBox>
      <MDBox mb={1} lineHeight={0} textAlign="center">
        <MDButton
          variant="contained"
          color="success"
          disabled={loading}
          onClick={() => {
            saveSprayer();
          }}
        >
          <Icon>save</Icon>&nbsp;save
        </MDButton>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default SprayerAdd;
