// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function SprayerSettings({ title, sprayInfo }) {
  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2}>
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
          sx={{ fontSize: "1.3rem", paddingBottom: "10px" }}
        >
          {title}
        </MDTypography>
        <MDBox opacity={0.3}>
          <Divider sx={{ background: "black" }} />
        </MDBox>
      </MDBox>
      {sprayInfo.sprayer.map((tractor, index) => (
        <MDBox pb={2} px={2} lineHeight={1.25}>
          <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
            <MDBox mt={0.5}>
              <MDTypography
                variant="caption"
                fontWeight="bold"
                color="text"
                textTransform="uppercase"
                sx={{ margin: "7px", fontSize: "1rem" }}
              >
                Sprayer{index + 1}:
              </MDTypography>
            </MDBox>
            <MDBox width="80%" ml={0.5}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ fontSize: "1rem" }}
              >
                {tractor.sprayer}
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
            <MDBox mt={0.5}>
              <MDTypography
                variant="caption"
                fontWeight="bold"
                color="text"
                textTransform="uppercase"
                sx={{ margin: "7px", fontSize: "1rem" }}
              >
                Gear:
              </MDTypography>
            </MDBox>
            <MDBox width="80%" ml={0.5}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ fontSize: "1rem" }}
              >
                {tractor.setting.gear}
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
            <MDBox mt={0.5}>
              <MDTypography
                variant="caption"
                fontWeight="bold"
                color="text"
                textTransform="uppercase"
                sx={{ margin: "7px", fontSize: "1rem" }}
              >
                Speed:
              </MDTypography>
            </MDBox>
            <MDBox width="80%" ml={0.5}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ fontSize: "1rem" }}
              >
                {`${tractor.setting.speed}km/h`}
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
            <MDBox mt={0.5}>
              <MDTypography
                variant="caption"
                fontWeight="bold"
                color="text"
                textTransform="uppercase"
                sx={{ margin: "7px", fontSize: "1rem" }}
              >
                Pressure:
              </MDTypography>
            </MDBox>
            <MDBox width="80%" ml={0.5}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ fontSize: "1rem" }}
              >
                {`${tractor.setting.pressure}PSI`}
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
            <MDBox mt={0.5}>
              <MDTypography
                variant="caption"
                fontWeight="bold"
                color="text"
                textTransform="uppercase"
                sx={{ margin: "7px", fontSize: "1rem" }}
              >
                Fan:
              </MDTypography>
            </MDBox>
            <MDBox width="80%" ml={0.5}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ fontSize: "1rem" }}
              >
                {tractor.setting.fan}
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
            <MDBox mt={0.5}>
              <MDTypography
                variant="caption"
                fontWeight="bold"
                color="text"
                textTransform="uppercase"
                sx={{ margin: "7px", fontSize: "1rem" }}
              >
                Nozzles:
              </MDTypography>
            </MDBox>
            <MDBox width="80%" ml={0.5}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ fontSize: "1rem" }}
              >
                {tractor.setting.nozzles}
              </MDTypography>
            </MDBox>
          </MDBox>
          <Divider />
        </MDBox>
      ))}
    </Card>
  );
}

// Setting default props
SprayerSettings.defaultProps = {};

// Typechecking props
SprayerSettings.propTypes = {
  title: PropTypes.string.isRequired,
  sprayInfo: PropTypes.objectOf.isRequired,
};

export default SprayerSettings;
