// react-routers components
// import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// React
// import { useEffect, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { TableContainer } from "@mui/material";

function WeedBlockListEdit({ title, sprayInfo, shadow, setSprayInfo }) {
  const handleAddOrchard = (orchard) => {
    setSprayInfo((prev) => {
      const newSpray = { ...prev };
      const newArray = [...prev.orchards]; // make a copy of the original array
      const orchardindex = prev.orchards.findIndex((i) => i.siteCode === orchard.siteCode);

      newArray[orchardindex].checked = !prev.orchards[orchardindex].checked; // update the object at the specified index
      // eslint-disable-next-line no-plusplus
      for (let l = 0; l < newArray[orchardindex].blocks.length; l++) {
        newArray[orchardindex].blocks[l].checked = newArray[orchardindex].checked;
      }
      newSpray.orchards = newArray;

      return newSpray; // return the new array
    });
  };

  const handleAddBlock = (orchardIndex, blockIndex) => {
    setSprayInfo((prev) => {
      const newSpray = { ...prev };
      const newArray = [...prev.orchards]; // make a copy of the original array
      newArray[orchardIndex].blocks[blockIndex].checked =
        !prev.orchards[orchardIndex].blocks[blockIndex].checked; // update the object at the specified index
      newSpray.orchards = newArray;
      return newSpray; // return the new array
    });
  };

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none", width: "100%" }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <TableContainer>
        <Table aria-label="simple table">
          <TableRow component="th">
            <TableCell sx={{ fontWeight: "bold" }} align="left">
              Orchards
            </TableCell>
          </TableRow>
          <TableBody>
            {sprayInfo.orchards.map((row, index) => (
              <Accordion key={row.siteCode}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <MDTypography
                      style={{ flex: 1 }}
                    >{`${row.siteCode} - ${row.siteName}`}</MDTypography>
                    <Checkbox checked={row.checked} onChange={() => handleAddOrchard(row)} />
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer>
                    <Table aria-label="blocks table">
                      <TableBody>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }} align="left">
                            Block
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }} align="left">
                            Varieties
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }} align="left">
                            Hectares
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }} align="left" />
                        </TableRow>
                        {row.blocks.map((block, blockIndex) => (
                          <TableRow
                            key={block.blockCode}
                            align="right"
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              backgroundColor: blockIndex % 2 === 0 ? "#b2d6bb" : "#e3fad4",
                            }}
                          >
                            <TableCell align="left">{block.blockCode}</TableCell>
                            <TableCell align="left">
                              {block.varieties.map((c) => c.varietyName).join("\r\n")}
                            </TableCell>
                            <TableCell align="left">
                              {block.varieties
                                .reduce((sum, item) => sum + parseFloat(item.hectares), 0)
                                .toFixed(2)}
                            </TableCell>
                            <TableCell align="left">
                              <Checkbox
                                checked={block.checked}
                                onChange={() => handleAddBlock(index, blockIndex)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

// Setting default props for the ProfilesList
WeedBlockListEdit.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
WeedBlockListEdit.propTypes = {
  title: PropTypes.string.isRequired,
  setSprayInfo: PropTypes.func.isRequired,
  shadow: PropTypes.bool,
  sprayInfo: PropTypes.objectOf.isRequired,
};

export default WeedBlockListEdit;
