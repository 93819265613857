// react-routers components
// import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// React
// import { useEffect } from "react";
// @mui material components

import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import PlusIcon from "@mui/icons-material/AddCircleOutlined";
import IconButton from "@mui/material/IconButton";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import ChemicalTable from "examples/Tables/ChemicalTable";

// firebase functions
// import {
//   getDoc,
//   doc,
//   setDoc,
//   Timestamp,
//   setDoc,
//   doc,
// } from "firebase/firestore";
import { TableContainer } from "@mui/material";

function OrchardListEdit({ title, orchardInfo, setOrchardInfo, varieties }) {
  const updateVarietyInfo = (value, property, index, vindex) => {
    setOrchardInfo((prev) => {
      const newSpray = { ...prev };
      const newArray = [...prev.blocks];
      newArray[index].varieties[vindex][property] = value;
      newSpray.blocks = newArray;
      return newSpray;
    });
  };
  const addVariety = (index) => {
    setOrchardInfo((prev) => {
      const newSpray = { ...prev };
      const newArray = [...prev.blocks];
      newArray[index].varieties.push({
        key: JSON.stringify(10000000000000000 * Math.random(8)),
        varietyCode: "",
        trees: "",
        hectares: "",
        yearPlanted: "",
        address: "",
        blockVarietyActive: true,
        varietyName: "",
        blockName: newArray[index].blockName,
        blockCode: newArray[index].blockName,
        manager: "",
        siteName: newSpray.siteName,
        siteCode: newSpray.siteCode,
        grower: "45 South",
        blockActive: true,
        siteActive: true,
      });
      newSpray.blocks = newArray;
      return newSpray;
    });
  };
  const addBlock = () => {
    setOrchardInfo((prev) => {
      const newSpray = { ...prev };
      const newArray = [...prev.blocks];
      newArray.push({
        blockActive: true,
        blockCode: "",
        blockName: "New Block",
        cropSureBlockID: "",
        varieties: [
          {
            key: JSON.stringify(10000000000000000 * Math.random(8)),
            varietyCode: "",
            trees: "",
            hectares: "",
            yearPlanted: "",
            address: "",
            blockVarietyActive: true,
            varietyName: "",
            blockName: "",
            blockCode: "",
            manager: "",
            siteName: newSpray.siteName,
            siteCode: newSpray.siteCode,
            grower: "45 South",
            blockActive: true,
            siteActive: true,
          },
        ],
      });
      newSpray.blocks = newArray;
      return newSpray;
    });
  };

  const updateVarietyActive = (index, vindex, variety) => {
    setOrchardInfo((prev) => {
      const newSpray = { ...prev };
      const newArray = [...prev.blocks];
      newArray[index].varieties[vindex].blockVarietyActive = !variety.blockVarietyActive;
      newSpray.blocks = newArray;
      return newSpray;
    });
  };
  return (
    <Card sx={{ height: "100%", width: "100%" }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <TableContainer>
        <Table aria-label="simple table">
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              Block Name
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              CropSure Block ID
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              Variety
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              CropSure Variety ID
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              Hectares
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              Trees
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              Year Established
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              Active
            </TableCell>
          </TableRow>

          <TableBody>
            {orchardInfo.blocks.map((block, index) => (
              <>
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    backgroundColor: index % 2 === 0 ? "#e3fad4" : "white",
                  }}
                >
                  <TableCell rowSpan={block.varieties.length + 2}>
                    <TextField
                      sx={{
                        fontSize: "1rem",
                        verticalAlign: "center",
                      }}
                      type="text"
                      value={block.blockName}
                      onChange={(e) => {
                        setOrchardInfo((prev) => {
                          const newSpray = { ...prev };
                          const newArray = [...prev.blocks];
                          newArray[index].blockName = e.target.value;
                          newSpray.blocks = newArray;
                          return newSpray;
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell rowSpan={block.varieties.length + 2}>
                    <TextField
                      sx={{
                        fontSize: "1rem",
                        verticalAlign: "center",
                      }}
                      type="text"
                      value={block.cropSureBlockID}
                      onChange={(e) => {
                        setOrchardInfo((prev) => {
                          const newSpray = { ...prev };
                          const newArray = [...prev.blocks];
                          newArray[index].cropSureBlockID = e.target.value;
                          newSpray.blocks = newArray;
                          return newSpray;
                        });
                      }}
                    />
                  </TableCell>
                </TableRow>
                {block.varieties.map((variety, vindex) => (
                  <TableRow
                    key={variety.key}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      backgroundColor: index % 2 === 0 ? "#e3fad4" : "white",
                    }}
                  >
                    <TableCell align="center">
                      <Select
                        sx={{
                          height: "50px",
                          minWidth: "100px",
                        }}
                        value={variety.varietyName}
                        onChange={(event) => {
                          const selectedValue = event.target.value;
                          updateVarietyInfo(selectedValue, "varietyName", index, vindex);
                          updateVarietyInfo(
                            varieties.find((unit) => unit.varietyName === selectedValue)
                              ?.varietyCode || "",
                            "varietyCode",
                            index,
                            vindex
                          );
                        }}
                      >
                        {varieties.length > 0 ? (
                          varieties.map((unit) => (
                            <MenuItem key={unit.key} value={unit.varietyName}>
                              {unit.varietyName}
                            </MenuItem>
                          ))
                        ) : (
                          <CircularProgress color="success" />
                        )}
                      </Select>
                    </TableCell>
                    <TableCell>
                      <TextField
                        sx={{
                          fontSize: "1rem",
                          verticalAlign: "center",
                        }}
                        type="text"
                        value={variety.cropSureBlockVarietyID}
                        onChange={(e) => {
                          updateVarietyInfo(
                            e.target.value,
                            "cropSureBlockVarietyID",
                            index,
                            vindex
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      <TextField
                        sx={{
                          fontSize: "1rem",
                          verticalAlign: "center",
                        }}
                        type="number"
                        value={variety.hectares}
                        onChange={(e) => {
                          updateVarietyInfo(e.target.value, "hectares", index, vindex);
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      <TextField
                        sx={{
                          fontSize: "1rem",
                          verticalAlign: "center",
                        }}
                        type="number"
                        value={variety.trees}
                        onChange={(e) => {
                          updateVarietyInfo(e.target.value, "trees", index, vindex);
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        sx={{
                          fontSize: "1rem",
                          verticalAlign: "center",
                        }}
                        type="number"
                        value={variety.yearPlanted}
                        onChange={(e) => {
                          updateVarietyInfo(e.target.value, "yearPlanted", index, vindex);
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox
                        checked={variety.blockVarietyActive}
                        onChange={() => {
                          updateVarietyActive(index, vindex, variety);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    backgroundColor: index % 2 === 0 ? "#e3fad4" : "white",
                    background:
                      index % 2 === 0
                        ? "linear-gradient(to right, #e3fad4 20%, #ace396 40%, #ace396 40%, #ace396 100%)"
                        : "linear-gradient(to right, white 20%, #ace396 40%, #ace396 40%, #ace396 100%)",
                  }}
                >
                  <TableCell colSpan={6} align="center">
                    <IconButton
                      color="black"
                      onClick={() => {
                        addVariety(index);
                      }}
                    >
                      <MDTypography fontSize="15px" pr="5px">
                        Add Variety
                      </MDTypography>
                      <PlusIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </>
            ))}
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                backgroundColor: "#ace396",
              }}
            >
              <TableCell colSpan={8} align="center">
                <IconButton
                  color="black"
                  onClick={() => {
                    addBlock();
                  }}
                >
                  <MDTypography fontSize="15px" pr="5px">
                    Add Block
                  </MDTypography>
                  <PlusIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

// Typechecking props for the ProfilesList
OrchardListEdit.propTypes = {
  title: PropTypes.string.isRequired,
};

export default OrchardListEdit;
