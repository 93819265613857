// react-routers components
// import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// React
import { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import { Edit, Save } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Button from "@mui/material/Button";
// import ChemicalTable from "examples/Tables/ChemicalTable";

// firebase functions
import {
  // getDoc,
  doc,
  setDoc,
  // Timestamp,
  // setDoc,
  // doc,
} from "firebase/firestore";
import { TableContainer } from "@mui/material";
import { db } from "../../../firebaseDb";

function ChemicalsList({ title, sprayInfo, shadow }) {
  const [chemicalList, setChemicals] = useState(sprayInfo.chemicals);
  const [loading, setLoading] = useState(false);
  const [selectedrow, setSelectedRow] = useState("");

  const setBatch = (value, index) => {
    setChemicals((prevArray) => {
      const newArray = [...prevArray];
      newArray[index].batchNo = value;
      return newArray;
    });
  };

  const saveChemicals = (index, key) => {
    const prevChem = chemicalList;
    prevChem[index].disabled = true;
    setSelectedRow(index);
    setLoading(true);
    setChemicals((prevArray) => {
      const newArray = [...prevArray];
      newArray[index].disabled = true;
      return newArray;
    });
    const chemRef = doc(db, "Sprays", key);
    setDoc(chemRef, { chemicals: prevChem }, { merge: true }).then(() => {
      setLoading(false);
    });
  };

  const editChemicals = (index) => {
    setChemicals((prevArray) => {
      const newArray = [...prevArray];
      newArray[index].disabled = false;
      return newArray;
    });
  };

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <TableContainer>
        <Table aria-label="simple table">
          <TableRow component="th">
            <TableCell sx={{ fontWeight: "bold" }} align="left">
              Chemical Name
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              Spray Rate
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              First Ingredient (per Tank)
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              Second Ingredient (per Tank)
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              Third Ingredient (per Tank)
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="center">
              Batch Number
            </TableCell>
          </TableRow>
          <TableBody>
            {chemicalList.map((row, index) => (
              <TableRow
                hover
                key={row.key}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">{row.chemicalName}</TableCell>
                <TableCell align="center">{`${
                  Math.round(
                    row.ingredients.reduce((sum, ingredient) => {
                      // Parse the ratio as a number
                      const ratio = parseFloat(ingredient.ratio);
                      // Multiply by tank capacity, divide by 100, and add to the sum
                      return sum + (ratio * sprayInfo.tankCapacity) / 100;
                    }, 0) * 100
                  ) / 100
                }L per Tank`}</TableCell>
                {row.ingredients.map((ing) => (
                  <TableCell>
                    <TableRow>
                      <TableCell align="center">{ing.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        {`${(ing.ratio * sprayInfo.sprayer[0].tankCapacity) / 100}L`}
                      </TableCell>
                    </TableRow>
                  </TableCell>
                ))}
                <TableCell align="center">
                  <TextField
                    InputProps={{
                      style: {
                        backgroundColor: row.disabled ? "lightgrey" : "lightgreen",
                      },
                    }}
                    value={row.batchNo}
                    disabled={row.disabled}
                    onChange={(event) => setBatch(event.target.value, index)}
                  />
                </TableCell>
                {loading && index === selectedrow && (
                  <TableCell align="center">
                    <CircularProgress />
                  </TableCell>
                )}
                {!loading && (
                  <TableCell align="center">
                    {!row.disabled && (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                          saveChemicals(index, sprayInfo.key);
                        }}
                      >
                        <Save />
                      </Button>
                    )}
                    {row.disabled && (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                          editChemicals(index);
                        }}
                      >
                        <Edit />
                      </Button>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

// Setting default props for the ProfilesList
ChemicalsList.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
ChemicalsList.propTypes = {
  title: PropTypes.string.isRequired,
  shadow: PropTypes.bool,
  sprayInfo: PropTypes.objectOf.isRequired,
};

export default ChemicalsList;
