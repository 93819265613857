// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Card from "@mui/material/Card";

function Chemicals({ spray, setSpray, chemicals, measures, units, sprayers, handlers }) {
  const handleSelectHandler = (x, index) => {
    setSpray((prevState) => {
      const newState = { ...prevState };
      const newArray = newState.chemicals;
      newArray[index] = { ...newArray[index], approvedHandler: x }; // update the property of the object at the specified index
      newState.chemicals = newArray;
      return newState; // return the new array
    });
  };

  const handleSelectUnit = (x, index) => {
    setSpray((prevState) => {
      const newState = { ...prevState };
      const newArray = newState.chemicals;
      newArray[index] = { ...newArray[index], unit: x }; // update the property of the object at the specified index
      newState.chemicals = newArray;
      return newState; // return the new array
    });
  };
  const setSprayRate = (index, value) => {
    if (!Number.isNaN(+value)) {
      setSpray((prevState) => {
        const newState = { ...prevState };
        const newArray = newState.chemicals;
        newArray[index] = { ...newArray[index], sprayRate: value }; // update the property of the object at the specified index
        newState.chemicals = newArray;
        return newState; // return the new array
      });
    }
  };

  const handleSelectSprayer = (x) => {
    if (spray.sprayer.map((s) => s.sprayer).includes(x.sprayer)) {
      setSpray((prevState) => ({
        ...prevState,
        sprayer: [...prevState.sprayer.filter((s) => s.sprayer !== x.sprayer)],
        tankCapacity: x.tankCapacity,
      }));
    } else {
      setSpray((prevState) => ({
        ...prevState,
        sprayer: [...prevState.sprayer, x],
        tankCapacity: x.tankCapacity,
      }));
    }
  };

  const handleSetSetting = (x, sprayerIndex) => {
    setSpray((prevState) => {
      const newState = { ...prevState };
      const newArray = newState.sprayer;
      newState.waterRate = x.sprayRate;
      newArray[sprayerIndex] = { ...newArray[sprayerIndex], setting: x }; // update the property of the object at the specified index
      newState.sprayer = newArray;
      newState.setting = x.settingName;
      return newState; // return the new array
    });
  };

  const handleSelectChemical = (x) => {
    if (spray.chemicals.map((c) => c.chemicalName).includes(x.chemicalName)) {
      setSpray((prevState) => ({
        ...prevState,
        chemicals: [...prevState.chemicals.filter((c) => c.chemicalName !== x.chemicalName)],
      }));
    } else {
      setSpray((prevState) => ({
        ...prevState,
        chemicals: [...prevState.chemicals, x],
      }));
    }
  };

  const handleSelectMeasure = (x, index) => {
    setSpray((prevState) => {
      const newState = { ...prevState };
      const newArray = newState.chemicals;
      newArray[index] = { ...newArray[index], measure: x }; // update the property of the object at the specified index
      newState.chemicals = newArray;
      return newState; // return the new array
    });
  };

  const updateFanValue = (index, newFanValue) => {
    setSpray((prevState) => {
      const sprayCopy = [...prevState.sprayer];
      sprayCopy[index] = {
        ...sprayCopy[index],
        setting: {
          ...sprayCopy[index].setting,
          fan: newFanValue,
        },
      };
      return { ...prevState, sprayer: sprayCopy };
    });
  };

  // Filter chemicals based on spray.weedspray
  const filteredChemicals = spray.weedspray
    ? chemicals.filter((chem) => chem.weedspray)
    : chemicals;
  return (
    <MDBox>
      <Grid container spacing={2} sx={{ padding: 3 }}>
        <Grid item xs={12} md={12} lg={4}>
          <FormControl size="large" sx={{ minWidth: "100%" }}>
            <InputLabel
              id="target-area"
              sx={{
                top: spray.chemicals.map((c) => c.chemicalName).length > 0 ? "-10px" : "0px",
              }}
            >
              <Icon
                sx={{
                  fontWeight: "bold",
                  color: ({ palette: { success } }) => success.main,
                  paddingRight: 2,
                }}
              >
                science
              </Icon>
              Chemical(s)
            </InputLabel>

            <Select
              sx={{ height: "50px" }}
              labelId="operator-label"
              id="operator-select"
              value={spray.chemicals.map((c) => c.chemicalName)}
              label="Chemical(s)___________________________-"
              multiple
            >
              {filteredChemicals.length > 0 ? (
                filteredChemicals.map((chem) => (
                  <MenuItem
                    key={chem.key}
                    value={chem.chemicalName}
                    onClick={() => {
                      handleSelectChemical({ ...chem, batchNo: "" });
                    }}
                  >
                    {chem.chemicalName}
                  </MenuItem>
                ))
              ) : (
                <CircularProgress color="success" />
              )}
            </Select>
          </FormControl>
        </Grid>
        <Card
          sx={{
            marginTop: "20px",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <Table>
            {spray.chemicals.length > 0 &&
              spray.chemicals.map((c, index) => (
                <TableRow>
                  <TableCell>
                    <TextField value={`${c.chemicalName}`} label="Chemical" disabled />
                  </TableCell>
                  <TableCell>
                    <FormControl size="large" sx={{ minWidth: "100%" }}>
                      <InputLabel>
                        <Icon
                          sx={{
                            fontWeight: "bold",
                            color: ({ palette: { success } }) => success.main,
                            paddingRight: 2,
                          }}
                        >
                          person
                        </Icon>
                        Approved Handler
                      </InputLabel>

                      <Select
                        sx={{ height: "40px", minWidth: "150px" }}
                        value={c.approvedHandler}
                        label="Approved Handler___"
                      >
                        {handlers.length > 0 ? (
                          handlers.map((hand) => (
                            <MenuItem
                              key={hand.key}
                              value={hand.name}
                              onClick={() => {
                                handleSelectHandler(hand.name, index);
                              }}
                            >
                              {hand.name}
                            </MenuItem>
                          ))
                        ) : (
                          <CircularProgress color="success" />
                        )}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <MDBox>
                      <TextField
                        value={`${c.sprayRate}`}
                        label="Chemical Amount"
                        onChange={(e) => setSprayRate(index, e.target.value)}
                      />
                    </MDBox>
                  </TableCell>
                  <TableCell>
                    <Select sx={{ height: "40px" }} value={c.unit} placeholder="Units">
                      {units.length > 0 ? (
                        units.map((op) => (
                          <MenuItem
                            key={op.key}
                            value={op.unit}
                            onClick={() => {
                              handleSelectUnit(op.unit, index);
                            }}
                          >
                            {op.unit}
                          </MenuItem>
                        ))
                      ) : (
                        <CircularProgress color="success" />
                      )}
                    </Select>
                  </TableCell>
                  <TableCell>per</TableCell>
                  <TableCell>
                    <Select sx={{ height: "40px" }} value={c.measure}>
                      {measures.length > 0 ? (
                        measures.map((op) => (
                          <MenuItem
                            key={op.key}
                            value={op.measure}
                            onClick={() => {
                              handleSelectMeasure(op.measure, index);
                            }}
                          >
                            {op.measure}
                          </MenuItem>
                        ))
                      ) : (
                        <CircularProgress color="success" />
                      )}
                    </Select>
                  </TableCell>
                </TableRow>
              ))}
          </Table>
        </Card>

        <Grid item xs={12} md={6} lg={4}>
          <MDBox>
            <MDBox>
              <FormControl size="large" sx={{ minWidth: "100%" }}>
                <InputLabel id="target-area">
                  <Icon
                    sx={{
                      fontWeight: "bold",
                      color: ({ palette: { success } }) => success.main,
                      paddingRight: 2,
                    }}
                  >
                    agriculture
                  </Icon>
                  Sprayer(s)
                </InputLabel>
                <Select
                  multiple
                  sx={{ height: "50px" }}
                  labelId="operator-label"
                  id="operator-select"
                  value={spray.sprayer.map((s) => s.sprayer)}
                  label="Sprayer_____"
                >
                  {sprayers.length > 0 ? (
                    sprayers.map((r) => (
                      <MenuItem
                        key={r.key}
                        value={r.sprayer}
                        onClick={() => {
                          handleSelectSprayer(r);
                        }}
                      >
                        {r.sprayer}
                      </MenuItem>
                    ))
                  ) : (
                    <CircularProgress color="success" />
                  )}
                </Select>
              </FormControl>
            </MDBox>
          </MDBox>
        </Grid>
        <Card
          sx={{
            marginTop: "20px",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <Table>
            {spray.sprayer.length > 0 &&
              spray.sprayer.map((s, index) => (
                <Table>
                  <TableRow>
                    <TableCell>
                      <TextField value={`${s.sprayer}`} label="Sprayer" disabled />
                    </TableCell>
                    <TableCell>
                      <FormControl size="large" sx={{ minWidth: "100%" }}>
                        <InputLabel>Setting</InputLabel>

                        <Select
                          sx={{ height: "40px", minWidth: "150px" }}
                          value={s.sprayRate}
                          label="Setting"
                        >
                          {s.settings?.length > 0 ? (
                            s.settings.map((set, settingIndex) => (
                              <MenuItem
                                key={set.settingName}
                                value={set.settingName}
                                onClick={() => {
                                  handleSetSetting(set, index, settingIndex);
                                }}
                              >
                                {set.settingName}
                              </MenuItem>
                            ))
                          ) : (
                            <CircularProgress color="success" />
                          )}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Water Rate: {spray.sprayer[index].setting?.settingName}</TableCell>
                    <TableCell>Speed: {spray.sprayer[index].setting?.speed}km/h</TableCell>
                    <TableCell>Gear: {spray.sprayer[index].setting?.gear}</TableCell>
                    <TableCell>Pressure: {spray.sprayer[index].setting?.pressure}psi</TableCell>
                    <TableCell>
                      <FormControl size="large" sx={{ minWidth: "100%" }}>
                        <InputLabel>Fan Setting</InputLabel>

                        <Select
                          sx={{ height: "40px", minWidth: "150px" }}
                          value={
                            spray.sprayer[index]?.setting?.fan
                              ? spray.sprayer[index]?.setting?.fan
                              : ""
                          }
                          label="Fan Setting"
                        >
                          <MenuItem
                            value="High"
                            onClick={() => {
                              updateFanValue(index, "High");
                            }}
                          >
                            High
                          </MenuItem>
                          <MenuItem
                            value="Low"
                            onClick={() => {
                              updateFanValue(index, "Low");
                            }}
                          >
                            Low
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                </Table>
              ))}
          </Table>
        </Card>
      </Grid>
    </MDBox>
  );
}

// Typechecking props
Chemicals.propTypes = {
  spray: PropTypes.objectOf(PropTypes.oneOf([PropTypes.string, PropTypes.array, PropTypes.object]))
    .isRequired,
  chemicals: PropTypes.objectOf(
    PropTypes.oneOf([PropTypes.string, PropTypes.array, PropTypes.object])
  ).isRequired,
  sprayers: PropTypes.objectOf(
    PropTypes.oneOf([PropTypes.string, PropTypes.array, PropTypes.object])
  ).isRequired,
  units: PropTypes.objectOf(PropTypes.oneOf([PropTypes.string, PropTypes.array, PropTypes.object]))
    .isRequired,
  measures: PropTypes.objectOf(
    PropTypes.oneOf([PropTypes.string, PropTypes.array, PropTypes.object])
  ).isRequired,
  handlers: PropTypes.objectOf(
    PropTypes.oneOf([PropTypes.string, PropTypes.array, PropTypes.object])
  ).isRequired,
  setSpray: PropTypes.func.isRequired,
};

export default Chemicals;
