import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Setup page components
import Chemicals from "layouts/setup/components/Chemicals";
import Employees from "layouts/setup/components/Employees";
import Sprayers from "layouts/setup/components/Sprayers";
import Orchards from "layouts/setup/components/Orchards";
// firebase functions
import { db } from "firebaseDb";
// import { getDocs, query, collection, orderBy } from "firebase/firestore";
import { getDocs, collection, query, orderBy } from "firebase/firestore";
import { CircularProgress } from "@mui/material";

function Billing() {
  const [chemicals, setChemicals] = useState([]);
  const [orchards, setOrchards] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [sprayers, setSprayers] = useState([]);
  const [varieties, setVarieties] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const chemRef = collection(db, "Chemicals");
    const q = query(chemRef, orderBy("chemicalName", "asc"));
    getDocs(q).then((querySnapshot) => {
      const tempchems = [];
      querySnapshot.forEach((docu) => {
        tempchems.push({ ...docu.data(), key: docu.id });
      });
      setChemicals(tempchems);
    });

    const orchardRef = collection(db, "Orchards");
    const oq = query(orchardRef, orderBy("siteCode", "asc"));
    getDocs(oq).then((querySnapshot) => {
      const siteData = {};

      querySnapshot.forEach((docu) => {
        const { siteCode, blockName, blockCode, varietyCode, ...rest } = docu.data();

        if (!siteData[siteCode]) {
          siteData[siteCode] = {
            key: docu.id,
            siteName: docu.data().siteName,
            cropSureID: docu.data().cropSureID,
            orchardGUID: docu.data().orchardGUID,
            siteCode,
            blocks: [],
          };
        } else if (!siteData[siteCode].orchardGUID && docu.data().orchardGUID) {
          // Overwrite orchardGUID if it's undefined in siteData but defined in the current document
          siteData[siteCode].orchardGUID = docu.data().orchardGUID;
        }
        const block = siteData[siteCode].blocks.find((b) => b.blockName === blockName);

        if (!block) {
          siteData[siteCode].blocks.push({
            blockCode,
            cropSureBlockID: docu.data().cropSureBlockID,
            blockName: docu.data().blockName,
            blockActive: docu.data().blockActive,
            varieties: [],
          });
        }

        siteData[siteCode].blocks.forEach((b) => {
          if (b.blockName === blockName) {
            b.varieties.push({
              key: docu.id,
              cropSureBlockVarietyID: docu.data().cropSureBlockVarietyID,
              varietyCode,
              ...rest,
            });
          }
        });
      });

      // Sort the "blocks" array within each "site" by "blockName"
      Object.values(siteData).forEach((site) => {
        site.blocks.sort((a, b) => a.blockName.localeCompare(b.blockName));
      });

      // Convert siteData object to an array of sites
      const result = Object.values(siteData);
      setOrchards(result); // Set the organized data outside the loop
    });

    const employeeRef = collection(db, "Operators");
    const qe = query(employeeRef, orderBy("name", "asc"));
    getDocs(qe).then((querySnapshot) => {
      const tempemployees = [];
      querySnapshot.forEach((docu) => {
        if (!docu.data().archive) {
          tempemployees.push({ ...docu.data(), key: docu.id });
        }
      });
      setEmployees(tempemployees);
    });

    const varietiesRef = collection(db, "Varieties");
    const qv = query(varietiesRef, orderBy("varietyName", "asc"));
    getDocs(qv).then((querySnapshot) => {
      const tempvarieties = [];
      querySnapshot.forEach((docu) => {
        tempvarieties.push({ ...docu.data(), key: docu.id });
      });
      setVarieties(tempvarieties);
    });

    const sprayersRef = collection(db, "Tractors");
    const qs = query(sprayersRef, orderBy("sprayerName", "asc"));
    getDocs(qs).then((querySnapshot) => {
      const tempsprayers = [];
      querySnapshot.forEach((docu) => {
        if (!docu.data().archive) {
          tempsprayers.push({ ...docu.data(), key: docu.id });
        }
      });
      setSprayers(tempsprayers);
      setLoading(false);
    });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox mt={8}>
        {!loading && (
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={7}>
                <Chemicals chemicals={chemicals} />
              </Grid>
              <Grid item xs={12} md={5}>
                <Employees employees={employees} />
              </Grid>
              <Grid item xs={12} md={7}>
                <Sprayers sprayers={sprayers} />
              </Grid>
              <Grid item xs={12} md={5}>
                <Orchards orchards={orchards} varieties={varieties} />
              </Grid>
            </Grid>
          </MDBox>
        )}
        {loading && (
          <MDBox mb={3} textAlign="center">
            <CircularProgress />
          </MDBox>
        )}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Billing;
