// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";

import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Sprayer page components
import Sprayer from "layouts/setup/components/Sprayer";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

import { useNavigate } from "react-router-dom";

function Sprayers({ sprayers }) {
  const [itemsToShow, setItemsToShow] = useState(2);
  const [searchQuery, setSearchQuery] = useState("");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const navigate = useNavigate();
  const handleLoadMore = () => {
    setItemsToShow((prevState) => prevState + 10);
  };

  const filteredSprayers = sprayers.filter((sprayer) =>
    sprayer.sprayerName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <Card id="delete-account">
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Sprayer Information
        </MDTypography>
        <TextField
          id="search"
          label="Search sprayers"
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
        />
      </MDBox>
      <MDButton
        variant="text"
        color={darkMode ? "white" : "dark"}
        onClick={() => {
          navigate(`/setup/addsprayer/`);
        }}
      >
        <Icon>add</Icon>&nbsp;Add Sprayer
      </MDButton>
      <MDBox pt={1} pb={1} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {filteredSprayers.slice(0, itemsToShow).map((sprayer) => (
            <Sprayer key={sprayer.key} info={sprayer} />
          ))}
        </MDBox>
        {filteredSprayers.length > itemsToShow && (
          <MDBox display="flex" justifyContent="center" alignItems="center" p={3}>
            Load more...
            <Icon
              sx={{ cursor: "pointer", fontWeight: "bold" }}
              fontSize="large"
              onClick={() => {
                handleLoadMore();
              }}
            >
              keyboard_arrow_down
            </Icon>
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
}
// Typechecking props
Sprayers.propTypes = {
  sprayers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};
export default Sprayers;
