// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function PurchaseCard({ title, shadow, invoiceData }) {
  const TimestamptoDate = (timestamp) => {
    const { seconds } = timestamp;
    const { nanoseconds } = timestamp;

    const milliseconds = seconds * 1000 + nanoseconds / 1e6;
    const date = new Date(milliseconds).toDateString();
    return date;
  };

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none", backgroundColor: "#FFF7D4" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
          sx={{ fontSize: "1rem" }}
        >
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox opacity={0.3}>
          <Divider sx={{ background: "black" }} />
        </MDBox>
        <MDBox>
          <MDTypography
            variant="h6"
            fontWeight="medium"
            textTransform="capitalize"
            sx={{ fontSize: "1rem" }}
          >
            {`${TimestamptoDate(invoiceData.startDate)} - ${TimestamptoDate(invoiceData.endDate)}`}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props
PurchaseCard.defaultProps = {
  shadow: true,
};

// Typechecking props
PurchaseCard.propTypes = {
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  invoiceData: PropTypes.any.isRequired,
  shadow: PropTypes.bool,
};

export default PurchaseCard;
