// react-routers components
// import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// React
import { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Masonry from "@mui/lab/Masonry";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// firebase functions
import { doc, setDoc, Timestamp } from "firebase/firestore";
import { db } from "../../../firebaseDb";

function TankList({ title, sprayInfo, shadow }) {
  const [tanks, setTanks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const setTankFinished = (index) => {
    setTanks((prev) => {
      const date = Timestamp.fromDate(new Date());
      const updatedTanks = [...prev]; // Create a copy of the array

      const updatedTank = { ...updatedTanks[index] }; // Create a copy of the tank object
      updatedTank.finished = !updatedTank.finished;
      updatedTank.operator = "Finished";
      // Need to change this to include the person logged in...
      updatedTank.timeFinished = date;

      updatedTanks[index] = updatedTank; // Replace the tank object in the copied array

      return updatedTanks;
    });
  };

  useEffect(() => {
    if (tanks.length > 0) {
      const docRef = doc(db, "Sprays", sprayInfo.key);
      setDoc(docRef, { tanks }, { merge: true }).then(() => {
        console.log("record changed");
      });
    }
  }, [tanks]);
  useEffect(() => {
    setLoading(false);
    // Calculate Tanks
    const temptanks = [];
    const numberofTanks = Math.ceil(
      (Number(sprayInfo.hectares) * Number(sprayInfo.waterRate)) / sprayInfo.tankCapacity
    );

    /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
    for (let i = 0; i < numberofTanks; i++) {
      if (i + 1 === numberofTanks) {
        temptanks.push({
          key: Math.random(10),
          operator: "",
          finished: false,
          timeFinished: "",
          title: `Part Tank`,
        });
      } else {
        temptanks.push({
          key: Math.random(10),
          operator: "",
          finished: false,
          timeFinished: "",
          title: `Tank ${i + 1}`,
        });
      }
    }
    // compare the two arrays and update if necessary
    let same = 0;
    if (!sprayInfo.tanks) {
      setTanks(temptanks);
    } else {
      if (temptanks.length !== sprayInfo.tanks.length) {
        same += 1;
      } else {
        for (let i = 0; i < Math.max(temptanks.length, sprayInfo.tanks.length); i++) {
          if (temptanks[i].title !== sprayInfo.tanks[i].title) {
            same += 1;
          }
        }
      }

      if (same > 0) {
        const docRef = doc(db, "Sprays", sprayInfo.key);
        setDoc(docRef, { tanks: temptanks }, { merge: true }).then(() => {
          console.log("record changed");
        });
        setTanks(temptanks);
      } else {
        setTanks(sprayInfo.tanks);
      }
    }
  }, []);

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <Masonry columns={4} spacing={2}>
        {tanks.map((tank, index) => (
          <Card
            key={tank.key}
            sx={{
              textAlign: "center",
              backgroundColor: tank.finished ? "#21130d" : "#f5b272",
              cursor: "pointer",
            }}
            onClick={() => {
              setCurrentIndex(index);
              setTankFinished(index);
            }}
          >
            {loading && index === currentIndex ? (
              <MDBox justifyContent="center">
                <CircularProgress size={20} />
              </MDBox>
            ) : (
              <MDTypography>{tank.finished ? tank.operator : tank.title}</MDTypography>
            )}
          </Card>
        ))}
      </Masonry>
    </Card>
  );
}

// Setting default props for the ProfilesList
TankList.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
TankList.propTypes = {
  title: PropTypes.string.isRequired,
  shadow: PropTypes.bool,
  sprayInfo: PropTypes.objectOf.isRequired,
};

export default TankList;
