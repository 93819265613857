// react-routers components
// import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// React
import { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@mui/material";

// import CircularProgress from "@mui/material/CircularProgress";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// firebase functions
import { doc, setDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebaseDb";

function WeatherList({ title, sprayInfo, shadow, setSprayInfo }) {
  const [weather, setWeather] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const tempWeather = [];
    const weatherRef = collection(db, "Weather");
    getDocs(weatherRef).then((querySnapshot) => {
      querySnapshot.forEach((element) => {
        tempWeather.push({ ...element.data(), key: element.id });
      });
      setLoading(false);
      setWeather(tempWeather);
    });
  }, []);

  const updateFirestore = (weatherType, value) => {
    const docRef = doc(db, "Sprays", sprayInfo.key);
    setDoc(docRef, { [weatherType]: value }, { merge: true }).then(() => {
      console.log("record changed");
    });
  };
  const handleSelectChange = (weatherType, value) => {
    setSprayInfo((prevValues) => ({
      ...prevValues,
      [weatherType]: value,
    }));

    // Save the selected value in Firestore
    updateFirestore(weatherType, value);
  };

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
        <Table>
          {!loading && (
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>Weather Type</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>Selection</TableCell>
              </TableRow>
              {weather.map((item) => (
                <TableRow key={item.key}>
                  <TableCell>{item.weatherType.toUpperCase()}</TableCell>
                  <TableCell>
                    <FormControl size="large" sx={{ width: "100%" }}>
                      <InputLabel id="weather" sx={{ width: "100%" }}>
                        {item.weatherType.toUpperCase()} VALUE
                      </InputLabel>
                      <Select
                        sx={{ height: "50px" }}
                        labelId="weather-label"
                        id="weather-select"
                        value={sprayInfo[item.weatherType] || ""}
                        label="Select a Really Long Value"
                        onChange={(e) => handleSelectChange(item.weatherType, e.target.value)}
                        displayEmpty
                        style={{ minWidth: 150 }}
                      >
                        {item.types.map((type) => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
          {loading && (
            <MDBox justifyContent="center" display="flex">
              <CircularProgress />
            </MDBox>
          )}
        </Table>
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfilesList
WeatherList.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
WeatherList.propTypes = {
  title: PropTypes.string.isRequired,
  shadow: PropTypes.bool,
  sprayInfo: PropTypes.objectOf.isRequired,
  setSprayInfo: PropTypes.func.isRequired,
};

export default WeatherList;
